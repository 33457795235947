import "moment-timezone";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../../useAuth";
import { useLazyCloseCallQuery } from "./useLazyCloseCallQuery";
import { useLazyLaunchCallQuery } from "./useLazyLaunchCallQuery";

export const useAppointmentVideoCall = () => {
  const { socket } = useAuth();
  const [searchParams] = useSearchParams();
  const callId = searchParams.get("appointmentId");
  const [callEnded, setCallEnded] = useState(false);
  const isTech = true;
  const [
    startCall,
    {
      isLoading: isCallStarting,
      isError: isErrorStarting,
      error: errorStarting,
      isSuccess: isSuccessStarting,
      data: callData,
    },
  ] = useLazyLaunchCallQuery();
  const [closeCall, { isLoading: isCallClosing }] = useLazyCloseCallQuery();

  useEffect(() => {
    if (callData?.status === "closed") {
      setCallEnded(true);
    }
  }, [callData?.status]);

  useEffect(() => {
    const onCallEnded = () => {
      setCallEnded(true);
    };
    if (socket) {
      socket.on("appointment_call_ended", onCallEnded);
    }
    return () => {
      socket?.off("appointment_call_ended", onCallEnded);
    };
  }, [socket]);

  return {
    callId,
    callEnded,
    callData,
    isCallClosing,
    isTech,
    isCallStarting,
    isErrorStarting,
    errorStarting,
    isSuccessStarting,
    setCallEnded,
    startCall,
    closeCall,
  };
  };
