export const isChromeIOS = () => {
  return /CriOS/i.test(navigator.userAgent);
};

export const isDesktop =
  !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const isSafariIOS = () => {
  return (
    /Safari/i.test(navigator.userAgent) && /iPhone/i.test(navigator.userAgent)
  );
};

export const isChromeAndroid = () => {
  return (
    /Chrome/i.test(navigator.userAgent) && /Android/i.test(navigator.userAgent)
  );
};

export const isChromeHMT1 = () => {
  return (
    /Chrome/i.test(navigator.userAgent) && /Android/i.test(navigator.userAgent)
  );
};
