import {
  Avatar,
  AvatarBadge,
  Button,
  HStack,
  IconButton,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { BiPhoneCall, BiPlusCircle } from "react-icons/bi";
import { IoReload } from "react-icons/io5";
import { useNavigate } from "react-router";
import CallIndicator from "../components/CallIndicator";
import MessageIncomingAppointmentCall from "../components/incomingCalls/MessageIncomingAppointmentCall";
import MessageIncomingCall from "../components/incomingCalls/MessageIncomingCall";
import { useCall } from "../hooks/calls/useCall";
import { useAuth } from "../hooks/useAuth";
import CenteredContentPage from "../layouts/CenteredContentPage";
import { CallableUser } from "../models/CallableUser";
import CallService from "../services/callService";
import { useApp } from "../hooks/useApp";
import DataItems from "../components/DataItems";
import { GeneralTableFilters } from "../types/AppTypes";

const CallableUsers = () => {
  const navigate = useNavigate();
  const { setTitle } = useApp();
  const toast = useToast();
  const { callableUsers, isLoading, isError, load } = useCall();
  const [filters, setFilters] = useState<GeneralTableFilters>({
    pageIndex: 0,
    pageSize: 4,
    sorting: [{ id: "status", desc: true }],
    text: "",
  });
  const [callingUser, setCallingUser] = useState<CallableUser>();
  const {
    enterpriseId,
    socket,
    incomingCall,
    incomingAppointmentCall,
    setIncomingCall,
    setIncomingAppointmentCall,
  } = useAuth();

  useEffect(() => {
    setTitle("Callable Users");
  }, [setTitle]);

  useEffect(() => {
    const onCallEnded = () => {
      load();
    };
    if (socket) {
      socket.on("direct_call_ended", onCallEnded);
    }
    return () => {
      socket?.off("direct_call_ended", onCallEnded);
    };
  }, [load, socket]);

  useEffect(() => {
    if (isError) {
      toast({ title: "error loading users", status: "error" });
    }
  }, [isError]);

  const joinCall = async (call: CallableUser) => {
    navigate(`/direct-call?callId=${call.join}`);
  };

  const callUser = async (callUser: CallableUser) => {
    try {
      setCallingUser(callUser);
      const callResponse = await CallService.callUser(enterpriseId!, {
        toUser: callUser._id,
      });
      navigate(`/direct-call?callId=${callResponse?.data?._id}`);
      load(); // reload list to display JOIN button
    } catch (error) {
      toast({ title: "Cannot create call", status: "error" });
    } finally {
      setCallingUser(undefined);
    }
  };

  const preProcessFilter = useCallback((filter: GeneralTableFilters) => {
    return {
      ...filter,
    };
  }, []);

  const CallableUserItem: React.FC<{ user: CallableUser; i: number }> = ({
    user,
    i,
  }) => (
    <HStack
      backgroundColor={"gray.100"}
      p={1}
      w="full"
      key={`k${i}-cu`}
      borderRadius={8}
    >
      <Avatar size="xs">
        <AvatarBadge
          boxSize="1.25em"
          bg={user.status === "online" ? "green.500" : "red.500"}
        />
      </Avatar>
      <Button
        size="sm"
        variant="primaryOutline"
        isDisabled={user.status !== "online" || !!user.join}
        isLoading={callingUser?._id === user._id}
        title={`Call ${user.name}`}
        onClick={() => callUser(user)}
      >
        <BiPhoneCall />
        <Text ml={2}>Call</Text>
      </Button>
      <Text fontSize="sm">
        {user.name} ({user.ubimaxId})
      </Text>
      {user.join && (
        <HStack justifyContent={"center"} alignItems={"center"}>
          <HStack>
            <CallIndicator
              colorTo={"rgb(0 220 0)"}
              colorFrom={"rgb(0 150 0)"}
            />
            <Text fontWeight={"bold"}>Active</Text>
          </HStack>
          <Button size={'md'} variant={"ghost"} onClick={() => joinCall(user)}>
            <HStack justifyContent={"center"} alignItems={"center"}>
              <BiPlusCircle />
              <Text>Join</Text>
            </HStack>
          </Button>
        </HStack>
      )}
    </HStack>
  );

  const renderItem = (item: CallableUser, index: number) => {
    return <CallableUserItem user={item} i={index} />;
  };

  return (
    <CenteredContentPage boxProps={{}}>
      <VStack w="full" alignItems="center" spacing={4}>
        <VStack
          alignItems="stretch"
          spacing={0}
          w="full"
          fontWeight="bold"
          margin="0,auto"
        >
          <DataItems
            data={callableUsers}
            fetchData={load}
            filter={filters}
            isLoading={isLoading}
            renderItem={renderItem}
            renderRefresh
            preProcessFilter={preProcessFilter}
          />
        </VStack>
        {!isLoading && !callableUsers?.length && (
          <Text>No users available to call</Text>
        )}
      </VStack>
    </CenteredContentPage>
  );
};

export default CallableUsers;
