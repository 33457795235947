// httpClient.js

import axios, { AxiosRequestConfig, CreateAxiosDefaults } from "axios";
import { GeneralTableFilters } from "../types/AppTypes";
import _ from "lodash";

const BASE_URL = `${import.meta.env.VITE_APP_API_HOST}/api/v1`;

interface createHttpClientProps extends CreateAxiosDefaults {}
const createHttpClient = (config?: createHttpClientProps) => {
  const instance = axios.create({
    baseURL: BASE_URL,
    ...config,
  });
  // Set the Bearer token in the Authorization header for all requests
  instance.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = localStorage.getItem("jwt");
    config.headers = config.headers ?? {};
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // if error while offline
      if (
        error.config?.url.match(
          /\/client\/process\/user-team\/[^/]+\/next-step\/[^/]+/
        )
      ) {
        const failedRequests =
          JSON.parse(localStorage.getItem("failedRequests") || "[]") || [];
        failedRequests.push({
          method: error.config.method,
          url: `${error.config.baseURL}/${error.config.url}`,
        });

        localStorage.setItem("failedRequests", JSON.stringify(failedRequests));
      }
      // Handle error globally
      return Promise.reject(error);
    }
  );

  return instance;
};

export const processFilters = (filters: GeneralTableFilters) => {
  const firstFilters = new URLSearchParams(
    _.omit(filters, "sorting")
  ).toString();

  return `${firstFilters} ${
    filters.sorting?.length
      ? `&sorting[]=${encodeURIComponent(JSON.stringify(filters.sorting[0]))}`
      : ""
  }`;
};

export default createHttpClient;
