import { CallUsers, LaunchCallUsers } from "../models/CallUsers";
import User from "../models/User";
import createHttpClient from "../utils/httlClient";

const client = createHttpClient();

const AppointmentService = {
  launchCall: async (enterpriseId: string, callId: string) => {
    const response = await client.post<LaunchCallUsers>(
      `enterprise/${enterpriseId}/appointment/call/create`,
      { id: callId }
    );
    return response;
  },
  // closeCall: async (enterpriseId: string, callId: string) => {
  //   const response = await client.get<undefined>(
  //     `enterprise/${enterpriseId}/call/${callId}/set-closed`
  //   );
  //   return response;
  // },
};

export default AppointmentService;
