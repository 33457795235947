import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import "@tldraw/tldraw/tldraw.css";
import { FaVideo } from "react-icons/fa";
import { useNavigate } from "react-router";
import VideoRecorder from "../components/videoCalls/VideoRecorder";
import { useVideoInstructions } from "../hooks/calls/useVideoInstructions";
import { CONFIG } from "../utils/constants";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const VideoInstructions = () => {
  const toast = useToast();
  const [callClosed, setCallClosed] = useState(false);
  // const [showRecordModal, setShowRecordModal] = useState(false);
  const [closingTime, setClosingTime] = useState<number | null>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const callId = searchParams.get("id");
  const {
    startRecording,
    stopRecording,
    handleStart,
    isCreateEnterpriseLoading,
    enterpriseLibrary,
    isStartRecordingLoading,
    isStopRecordingLoading,
    isCreateEnterpriseError,
    isStopRecordingError,
    isStartRecordingError,
    isStopRecordingSuccess,
    isStartRecordingSuccess,
    isCreateEnterpriseSuccess,
    getEnterpriseLibrary,
    isGetEnterpriseLoading,
    isGetEnterpriseError,
  } = useVideoInstructions();

  // const enterpriseLibrary = { _id: "66abab52004a26858474366a", name: "demo" };

  const onCloseCall = useCallback(() => {
    setCallClosed(true);
    navigate("/video-instructions");
    window.location?.reload();
  }, []);

  useEffect(() => {
    if (isCreateEnterpriseError) {
      toast({
        status: "error",
        description: "Cannot create enterprise library",
      });
    }
  }, [isCreateEnterpriseError]);

  useEffect(() => {
    if (isStartRecordingError) {
      toast({
        status: "error",
        description: "Cannot start recording",
      });
    }
  }, [isStartRecordingError]);

  useEffect(() => {
    if (isStopRecordingError) {
      toast({
        status: "error",
        description: "Cannot stop recording",
      });
    }
  }, [isStopRecordingError]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isStopRecordingSuccess) {
      setClosingTime(10);
      toast({
        status: "success",
        description: "Video stopped",
      });
      intervalId = setInterval(() => {
        setClosingTime((t) => (t ? (t >= 1 ? t - 1 : 0) : 0));
      }, 1 * 1000);
    }
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [isStopRecordingSuccess]);

  useEffect(() => {
    if (isStartRecordingSuccess) {
      toast({
        status: "success",
        description: "Recording...",
      });
    }
  }, [isStartRecordingSuccess]);

  useEffect(() => {
    if (callId) {
      getEnterpriseLibrary(callId);
    }
  }, [callId]);

  useEffect(() => {
    if (enterpriseLibrary?._id) {
      const params = new URLSearchParams();
      params.set("id", enterpriseLibrary?._id);
      navigate(`/video-instructions?${params.toString()}`);
    }
  }, [enterpriseLibrary?._id]);

  useEffect(() => {
    if (isStopRecordingSuccess && closingTime === 0) {
      onCloseCall();
    }
  }, [isStopRecordingSuccess, onCloseCall, closingTime]);

  return (
    <VStack
      alignItems={"center"}
      justifyContent={"center"}
      w={"full"}
      pos={enterpriseLibrary ? "absolute" : "inherit"}
      top={0}
      height={`calc(var(--vh, 1vh) * ${CONFIG.MAIN_HEIGHT})`}
    >
      {isGetEnterpriseLoading && <Spinner />}
      {/* <Text>Once Ready click on record to start recording your instructions</Text> */}
      {!isGetEnterpriseLoading && !enterpriseLibrary && (
        <VStack>
          <Text fontSize={"xl"} fontWeight={"semibold"} color={"white"}>
            Press start to start your video instructions.
          </Text>
          <Button isLoading={isCreateEnterpriseLoading} onClick={handleStart}>
            Start
          </Button>
        </VStack>
      )}
      {/* {enterpriseLibrary && (
        <Text fontSize={"xl"} color={"white"}>
          Session started {enterpriseLibrary.name}
        </Text>
      )} */}
      {/* if user closes camera. Let's add a chance to continue recording later in the same session */}
      {/* {enterpriseLibrary && callClosed && (
        <Button onClick={() => setCallClosed(false)}>Open Camera</Button>
      )} */}
      {enterpriseLibrary && !callClosed && (
        <VideoRecorder
          channelId={enterpriseLibrary?._id}
          startRecording={startRecording}
          stopRecording={stopRecording}
          isStartRecordingLoading={isStartRecordingLoading}
          isStopRecordingLoading={isStopRecordingLoading}
          onCloseCallCallback={onCloseCall}
        />
      )}
      <Modal
        isOpen={closingTime !== null && closingTime > 0}
        onClose={onCloseCall}
      >
        <ModalOverlay zIndex={100000} />
        <ModalContent
          containerProps={{
            zIndex: "100000",
          }}
        >
          <ModalHeader>Record</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Thank you for uploading your instructions. It may take a few minutes
            to process. We appreciate your patience!
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseCall}>
              Close
            </Button>
            <Text>
              Closing in {closingTime} second{closingTime !== 1 ? "s" : ""}
            </Text>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default VideoInstructions;
