import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { CallableUser } from "../../models/CallableUser";
import CallService from "../../services/callService";
import { useAuth } from "../useAuth";

export const useCall = () => {
  const { enterpriseId } = useAuth();
  const [callableUsers, setCallableUsers] = useState<CallableUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);

  const load = useCallback(async () => {
    if (enterpriseId) {
      try {
        setIsLoading(true);
        const res = await CallService.getCallableUsers(enterpriseId);
        setCallableUsers(res.data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          error.response?.data || error.message;
        }
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }
  }, [enterpriseId])

  useEffect(() => {
    if (enterpriseId) {
      load();
    }
  }, [enterpriseId, load]);

  return { callableUsers, isLoading, isError, load };
};
