import "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../useAuth";
import { useLazyCloseCallQuery } from "./useLazyCloseCallQuery";
import { useLazyLaunchCallQuery } from "./useLazyLaunchCallQuery";
import { IncomingCall } from "../../models/IncomingCall";
import { usePingCall } from "./usePingCall";

export const useDirectVideoCall = () => {
  const { socket } = useAuth();
  const [searchParams] = useSearchParams();
  const callId = searchParams.get("callId");
  const [callEnded, setCallEnded] = useState(false);
  const isTech = true;
  const [
    startCall,
    {
      isLoading: isCallStarting,
      isError: isErrorStarting,
      error: errorStarting,
      isSuccess: isSuccessStarting,
      data: callData,
    },
  ] = useLazyLaunchCallQuery();
  const [closeCall, { isLoading: isCallClosing }] = useLazyCloseCallQuery();
  usePingCall({ callId });

  useEffect(() => {
    if (callData?.status === "closed") {
      setCallEnded(true);
    }
  }, [callData?.status]);

  useEffect(() => {
    const onCallEnded = (data: IncomingCall) => {
      if (callId === data.callId) {
        setCallEnded(true);
        closeCall(callId);
      }
    };
    if (socket) {
      socket.on("direct_call_ended", onCallEnded);
    }
    return () => {
      socket?.off("direct_call_ended", onCallEnded);
    };
  }, [callId, closeCall, socket]);

  useEffect(() => {
    const onCallEnded = (data: IncomingCall) => {
      if (callId === data.callId) {
        setCallEnded(true);
        closeCall(callId);
      }
    };
    if (socket) {
      socket.on("direct_call_not_aswered", onCallEnded);
    }
    return () => {
      socket?.off("direct_call_not_aswered", onCallEnded);
    };
  }, [callId, closeCall, socket]);

  return {
    callId,
    callEnded,
    callData,
    isCallClosing,
    isTech,
    isCallStarting,
    isErrorStarting,
    errorStarting,
    isSuccessStarting,
    setCallEnded,
    startCall,
    closeCall,
  };
};
