import React from 'react';
import {
  Box,
  Flex,
  IconButton,
  Image,
  Text,
  useBreakpointValue,
  Tooltip
} from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useAuth } from "../hooks/useAuth";
import { useState } from "react";
import { FiHome } from "react-icons/fi";
import icon from "../../public/logo192.png";
import useNavigatorOnLine from "../hooks/calls/useNetworkStatus";
import { useApp } from "../hooks/useApp";
import ConfigOptions from "../pages/ConfigOptions";
import COLORS from "../utils/colors";

const Layout = () => {
  const auth = useAuth();
  const location = useLocation();
  const isVideoCall = ["/direct-call", "/appointment-call"].includes(
    location.pathname
  );

  return (
    <Flex direction="column" h="100vh">
      {auth.user && !isVideoCall && <Header />}
      <Box
        flex={1}
        w="full"
        bg={COLORS.primary}
        overflowY="auto"
      >
        <Outlet />
      </Box>
    </Flex>
  );
};

function Header() {
  const navigate = useNavigate();
  const { title } = useApp();
  const isOnline = useNavigatorOnLine();
  const [showOptions, setShowOptions] = useState(false);

  const failedRequests: [{ method: string; url: string }] =
    JSON.parse(localStorage.getItem("failedRequests") || "[]") || [];

  const goHome = () => navigate("/dashboard", { replace: true });

  const fontSize = useBreakpointValue({ base: "sm", md: "md" });
  const iconSize = useBreakpointValue({ base: "sm", md: "md" });
  const headerHeight = useBreakpointValue({ base: "40px", md: "50px" });

  return (
    <Flex
      w="full"
      bg={COLORS.secondary}
      justifyContent="space-between"
      alignItems="center"
      px={2}
      h={headerHeight}
    >
      <Image src={icon} h="full" maxH="30px" objectFit="contain" />

      <Text
        fontSize={fontSize}
        color={COLORS.primary}
        fontWeight="bold"
        textAlign="center"
        flex={1}
        isTruncated
        mx={2}
      >
        {title}
      </Text>

      <Flex alignItems="center">
        {!isOnline && (
          <Tooltip label="Offline" placement="bottom">
            <Box
              borderRadius="full"
              w={2}
              h={2}
              bg="red.500"
              mr={2}
            />
          </Tooltip>
        )}
        <IconButton
          onClick={goHome}
          size={iconSize}
          icon={<FiHome />}
          variant="ghost"
          aria-label="Home"
          mr={1}
        />
        <IconButton
          onClick={() => setShowOptions(true)}
          size={iconSize}
          icon={
            <Flex position="relative">
              <BsThreeDotsVertical />
              {failedRequests?.length > 0 && (
                <Box 
                  position="absolute" 
                  top="-2px" 
                  right="-2px" 
                  w="6px" 
                  h="6px" 
                  bg="red.500" 
                  borderRadius="full" 
                />
              )}
            </Flex>
          }
          variant="ghost"
          aria-label="Options"
        />
      </Flex>
      {showOptions && <ConfigOptions onClose={() => setShowOptions(false)} />}
    </Flex>
  );
}

export default Layout;