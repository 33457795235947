import User from "../models/User";
import { hanldeCleanSession } from "../utils/auth";
import createHttpClient from "../utils/httlClient";

const client = createHttpClient();

const AuthService = {
  qrCodeLogin: async (jwt: string) => {
    const response = await client.post<User>(`/auth/login-data-by-jwt`, { jwt });
    return response;
  },
  signInWithCredentials: async (username: string, password: string) => {
    const response = await client.post<User>(`/auth/login-smart-glasses`, { username, password });
    return response;
  },
  logout: async () => {
    // const response = await client.post(`/auth/logout`, { jwt });
    hanldeCleanSession()
    // return response.data;
  },
  getUser: async () => {
    const response = await client.post<User>(`/auth/check-session`);
    return response;
  },
};

export default AuthService