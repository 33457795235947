import { Box } from "@chakra-ui/react";
import React from "react";
import { useAuth } from "../../hooks/useAuth";
import MessageIncomingCall from "../incomingCalls/MessageIncomingCall";
import MessageIncomingAppointmentCall from "../incomingCalls/MessageIncomingAppointmentCall";
import _ from "lodash";

const GlobalNotifications: React.FC = () => {
  const {
    incomingCall,
    incomingAppointmentCall,
    setIncomingCall,
    setIncomingAppointmentCall,
  } = useAuth();

  const call = _.last(incomingCall);
  return (
    <Box>
      {call && (
        <MessageIncomingCall
          call={call}
          onAccept={() => {
            // load(); // reload list to show JOIN
          }}
          onCancelled={() => {
            setIncomingCall(
              incomingCall?.filter((ic) => ic.callId !== call?.callId)
            );
            // TODO: close call or not?
            // const callId =
            //   notificationState.showIncomingCallNotification?.callId;
            // if (callId) {
            //   closeCall({ enterpriseId, id: callId });
            // }
          }}
          onClose={() => {
            setIncomingCall(
              incomingCall?.filter((ic) => ic.callId !== call?.callId)
            );
          }}
        />
      )}
      {incomingAppointmentCall && (
        <MessageIncomingAppointmentCall
          call={incomingAppointmentCall}
          onCancelled={() => {
            setIncomingAppointmentCall(undefined);
            // TODO: close call or not?
            // const callId =
            //   notificationState.showIncomingCallNotification?.callId;
            // if (callId) {
            //   closeCall({ enterpriseId, id: callId });
            // }
          }}
          onClose={() => {
            setIncomingAppointmentCall(undefined);
          }}
        />
      )}
    </Box>
  );
};

export default GlobalNotifications;
