import {
  Button,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { IncomingAppointmentCall } from "../../models/IncomingAppointmentCall";
import { useNavigate } from "react-router";

interface MessageIncomingCallProps {
  call: IncomingAppointmentCall;
  onClose: () => void;
  onCancelled: () => void;
}
const MessageIncomingAppointmentCall: React.FC<MessageIncomingCallProps> = ({
  onClose,
  onCancelled,
  call,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={!!call}
      // isCentered
      motionPreset="slideInBottom"
      size="md"
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={"center"}>
          <Text>{call?.host} Started Appointment Meeting</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <HStack w={"full"} justifyContent={"space-between"}>
            <Button
              variant={"accept"}
              onClick={() => {
                onClose();
                navigate(`/appointment-call?appointmentId=${call?.apptId}`);
              }}
            >
              Accept
            </Button>
            <Button
              variant={"cancel"}
              onClick={() => {
                onCancelled();
              }}
            >
              Decline
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MessageIncomingAppointmentCall;
