import { Box, Center, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";

interface HomeOptionItemProps {
  icon: any;
  label: string;
  onClick: () => void;
}
const HomeOptionItem: React.FC<HomeOptionItemProps> = ({
  icon,
  label,
  onClick,
}) => {
  return (
    <VStack
      justifyContent={"center"}
      borderRadius={22}
      p={8}
      boxShadow="lg"
      title={label}
      onClick={onClick}
    >
      <VStack justifyContent={"center"} alignItems={"center"}>
        <Image src={icon} />
        <Text>{label}</Text>
      </VStack>
    </VStack>
  );
};

export default HomeOptionItem;
