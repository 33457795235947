/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  HStack,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import {
  createCameraVideoTrack,
  createClient,
  createMicrophoneAudioTrack,
  createScreenVideoTrack,
  setLogLevel,
} from "agora-rtc-sdk-ng/esm";
import moment from "moment";
import { LegacyRef, useCallback, useEffect, useRef, useState } from "react";
import { BiMicrophone, BiMicrophoneOff } from "react-icons/bi";
import { FaStop } from "react-icons/fa";
import { FiCamera, FiCameraOff } from "react-icons/fi";
import { IoIosPeople } from "react-icons/io";
import { MdScreenShare, MdStopScreenShare } from "react-icons/md";
import { TiMediaRecord } from "react-icons/ti";
import CallIndicator from "../CallIndicator";
import { useAuth } from "../../hooks/useAuth";
import WhiteBoard from "./WhiteBoard";
import AgoraRTC, { IMicrophoneAudioTrack } from "agora-rtc-sdk-ng";
import { LaunchCallUsers } from "../../models/CallUsers";
import axios from "axios";
const APP_ID = import.meta.env.VITE_APP_AGORA_VIDEO;
import { SiSourcehut } from "react-icons/si";
import { MdHideSource } from "react-icons/md";
import { CONFIG } from "../../utils/constants";
import { isDesktop } from "../../utils/video";
import { retryWithDelay } from "../../utils/helpers";
import COLORS from "../../utils/colors";

setLogLevel(3);

const resolutions = [
  { width: 3840, height: 2160 }, // 4K
  { width: 2560, height: 1440 }, // 2K
  { width: 1920, height: 1080 },
  { width: 1280, height: 720 },
  { width: 1024, height: 768 },
  { width: 640, height: 480 },
  // Add more resolutions as needed
];

const calculateEllapsedTime = (duration: moment.Duration) => {
  if (duration) {
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;
    const formattedSeconds = (seconds < 10 ? "0" : "") + seconds;
    const meetingEllapsedTime = `${hours}:${formattedMinutes}:${formattedSeconds}`;
    return meetingEllapsedTime;
  }
  return "";
};

interface IRemoteVideoProps {
  largeVideoRef: LegacyRef<HTMLVideoElement> | undefined;
  callEnded: boolean;
  otherName: string;
  isVideoMuted: boolean;
  isAudioMuted: boolean;
  isTech: boolean;
  width: string | number;
}
const RemoteVideo: React.FC<IRemoteVideoProps> = ({
  callEnded,
  otherName,
  largeVideoRef,
  isVideoMuted,
  isAudioMuted,
  width,
  isTech,
}) => {
  return (
    <Box
      w={width || "100%"}
      display={callEnded ? "none" : "block"}
      pos={"absolute"}
      top={0}
    >
      <Box position={"relative"} colSpan={1} w="full">
        <video
          ref={largeVideoRef}
          style={{
            borderWidth: 2,
            borderColor: "black",
            borderStyle: "solid",
            borderRadius: 2,
            background: "black",
          }}
          width={window.innerWidth}
          id="remote-video"
        />
        <HStack
          pos={"absolute"}
          bottom={0}
          left={0}
          right={0}
          justifyContent={"center"}
        >
          <Box
            padding={isTech ? 1 : 4}
            borderRadius={4}
            backgroundColor={"gray.100"}
          >
            {isVideoMuted ? <FiCameraOff /> : <FiCamera />}
          </Box>
          <Box
            padding={isTech ? 1 : 4}
            borderRadius={4}
            backgroundColor={"gray.100"}
          >
            {isAudioMuted ? <BiMicrophoneOff /> : <BiMicrophone />}
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};

interface IYouVideoProps {
  isVideoOn: boolean;
  width: number | string;
}
const YouVideo: React.FC<IYouVideoProps> = ({ isVideoOn, width }) => {
  return (
    <VStack
      w={width || "15%"}
      pos={"relative"}
      top={0}
      left={0}
      zIndex={1}
      width={"auto"}
      height={`calc(var(--vh, 1vh) * ${CONFIG.MAIN_HEIGHT})`}
    >
      <Text
        zIndex={1}
        position={"absolute"}
        bottom={0}
        fontSize={"sm"}
        fontWeight={"bold"}
        paddingLeft={2}
        paddingRight={2}
        backgroundColor={"rgb(255 255 255 / 50%)"}
      >
        {/* You */}
      </Text>
      <video
        style={{
          borderWidth: 2,
          borderColor: "black",
          borderStyle: "solid",
          borderRadius: 2,
          background: "black",
          // width: "192px",
          // height: "108px",
          // width: "auto",
          // height: `${(CONFIG.MAIN_HEIGHT / 100) * window.innerHeight}px`,
          transform: "none",
        }}
        id="camera-video"
        hidden={isVideoOn ? false : true}
      />
    </VStack>
  );
};

interface IBottomActionsProps {
  handleMuteUnmute: () => void;
  audioTrack: any;
  videoTrack: any;
  handleHideShowVideo: () => void;
  handleHideShowYourStream: () => void;
  hideYourVideo: boolean;
  setIsScreenShare: React.Dispatch<React.SetStateAction<boolean>>;
  isScreenShare: boolean;
  meetingEllapsedTime: string | undefined;

  isTech: boolean;
  onCloseCall: () => void;
  handleRecord: () => void;
  isRecording: boolean;
  toast: any;
}
const BottomActions: React.FC<IBottomActionsProps> = ({
  handleMuteUnmute,
  audioTrack,
  videoTrack,
  handleHideShowVideo,
  setIsScreenShare,
  isScreenShare,
  meetingEllapsedTime,
  isTech,
  onCloseCall,
  toast,
  handleRecord,
  isRecording,
  hideYourVideo,
  handleHideShowYourStream,
}) => (
  <HStack
    w={"full"}
    alignSelf={"flex-end"}
    justifyContent={"space-between"}
    pos={"absolute"}
    bottom={0}
    left={0}
    right={0}
    paddingX={5}
    zIndex={1800}
  >
    <HStack gap={0} justifyContent={"center"} backgroundColor={"white"}>
      <Button
        size={"sm"}
        padding={[0, "sm", "md"]}
        paddingInlineStart={0}
        paddingInlineEnd={0}
        variant={"ghost"}
        onClick={handleMuteUnmute}
        title={audioTrack.current?.muted ? "Unmute" : "Mute"}
      >
        {audioTrack.current?.muted ? <BiMicrophoneOff /> : <BiMicrophone />}
      </Button>
      <Button
        size={"sm"}
        padding={[0, "sm", "md"]}
        paddingInlineStart={0}
        paddingInlineEnd={0}
        variant={"ghost"}
        title={videoTrack.current?.muted ? "Start" : "Hide"}
        onClick={handleHideShowVideo}
      >
        {videoTrack.current?.muted ? <FiCameraOff /> : <FiCamera />}
      </Button>
      {!isTech && (
        <Button
          size={"sm"}
          onClick={() => {
            setIsScreenShare(!isScreenShare);
          }}
          variant={"ghost"}
          title={isScreenShare ? "Stop Sharing" : "Start Sharing"}
        >
          {isScreenShare ? <MdStopScreenShare /> : <MdScreenShare />}
        </Button>
      )}
      <Button
        size={"sm"}
        onClick={() => {
          // TODO
          toast({
            status: "success",
            title: "Link copied to clipboard!",
          });
        }}
        variant={"ghost"}
        title={"Share"}
      >
        <IoIosPeople size={25} />
      </Button>
      <Button
        size={"sm"}
        onClick={handleRecord}
        variant={"ghost"}
        title={"Record"}
      >
        {isRecording ? (
          <HStack>
            <FaStop size={25} />
            <CallIndicator />
          </HStack>
        ) : (
          <TiMediaRecord size={25} />
        )}
      </Button>
      <Button
        size={"sm"}
        onClick={handleHideShowYourStream}
        variant={"ghost"}
        title={hideYourVideo ? "Show cameras" : "Hide cameras"}
      >
        {hideYourVideo ? <MdHideSource size={25} /> : <SiSourcehut size={20} />}
      </Button>
    </HStack>
    <HStack backgroundColor={"white"}>
      <Text fontSize={"sm"} fontWeight={"bold"}>
        Duration:
      </Text>
      <Text fontSize={"sm"}>{meetingEllapsedTime}</Text>
    </HStack>
    {true && (
      <HStack>
        <Button size={"sm"} colorScheme={"red"} onClick={onCloseCall}>
          Close
        </Button>
      </HStack>
    )}
  </HStack>
);

const client = createClient({
  mode: "rtc",
  codec: "vp8",
});

interface IVideoPlayer {
  startCall: (callId: string) => Promise<LaunchCallUsers>;
  setCallEnded: any;
  closeCall: any;
  callEnded: any;
  isCallStarting: any;
  channelId: any;
  isErrorStarting: any;
  errorStarting: any;
  isSuccessStarting: any;
  isTech: any;
  callData: LaunchCallUsers;
  callId: any;
}
const VideoPlayer: React.FC<IVideoPlayer> = ({
  startCall,
  setCallEnded,
  closeCall,
  callEnded,
  isCallStarting,
  channelId,
  isErrorStarting,
  errorStarting,
  isSuccessStarting,
  isTech,
  callData,
  callId,
}) => {
  const { enterpriseId, user, socket } = useAuth();
  const toast = useToast();
  const channel = useRef(channelId);
  // you can apply appid follow the guide https://www.agora.io/en/blog/how-to-get-started-with-agora/
  const appid = useRef(APP_ID);
  // you can apply token follow the guide https://www.agora.io/en/blog/how-to-get-started-with-agora/
  const token = useRef("");
  const largeVideoRef = useRef<any>();
  const [videoHeight, setVideoHeight] = useState<number | string>();
  const [aux, setAux] = useState("");
  const [supportedResolutions, setSupportedResolutions] =
    useState<{ width: number; height: number }[]>();
  const [isAudioOn, setIsAudioOn] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [isAudioPubed, setIsAudioPubed] = useState(false);
  const [isVideoPubed, setIsVideoPubed] = useState(false);
  const [isRemoteVideoPubed, setIsRemoteVideoPubed] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isScreenShare, setIsScreenShare] = useState(false);
  const [hideYourVideo, setHideYourVideo] = useState(false);
  const [meetingEllapsedTime, setMeetingEllapsedTime] = useState<string>();
  const [isJoined, setIsJoined] = useState(false);
  const [otherName, setOtherName] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [launchedAt, setLaunchedAt] = useState<Date>();
  const audioTrack = useRef<any>();
  const videoTrack = useRef<any>();

  useEffect(() => {
    window.addEventListener("beforeunload", async () => {
      await leaveCall();
    });
    return () => {
      leaveCall();
    };
  }, []);

  useEffect(() => {
    if (callData?.snapshots?.length) {
      setHideYourVideo(true);
    }
  }, [callData]);

  useEffect(() => {
    // Access the height of the video element after it has been rendered
    if (largeVideoRef.current) {
      const videoHeight = largeVideoRef.current.clientHeight;
      setVideoHeight(`${videoHeight}px`);
      console.log("Video Height:", videoHeight);
    }
  }, [largeVideoRef.current?.clientHeight]);

  useEffect(() => {
    // Attach event listener for the 'onloadeddata' event
    const handleLoadedData = () => {
      console.log("changed resolution");
      // updateVideoHeight();
      const videoHeight = largeVideoRef.current?.clientHeight;
      setVideoHeight(`${videoHeight}px`);
    };

    if (largeVideoRef.current) {
      largeVideoRef.current.addEventListener("resize", handleLoadedData);
    }
  }, []);

  useEffect(() => {
    if (launchedAt) {
      const intervalId = setInterval(() => {
        const now = moment();
        const duration = moment.duration(now.diff(launchedAt));
        const ellapsedTime = calculateEllapsedTime(duration);
        setMeetingEllapsedTime(ellapsedTime);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [launchedAt]);

  // Function to get all video tracks with their resolutions
  async function findSupportedResolutions() {
    const supportedResolutionsAux = [];

    for (const resolution of resolutions) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { width: resolution.width, height: resolution.height },
        });
        // If we reach this point, the resolution is supported
        supportedResolutionsAux.push(resolution);
        // We should stop using the stream once we know it works
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        console.log(
          `Resolution ${resolution.width}x${resolution.height} is not supported.`
        );
      }
    }

    console.log("Supported resolutions:", supportedResolutionsAux);
    setSupportedResolutions(supportedResolutionsAux);
    return supportedResolutionsAux;
  }

  // console.log('supportedResolutions', supportedResolutions);

  const handleResolutions = (
    supportedResolutions: { width: number; height: number }[]
  ) => {
    console.log('isDesktop, supportedResolutions', isDesktop, supportedResolutions);
    if (!supportedResolutions) return;
    
    const DESIRED_HMT1 = { width: 2560, height: 1440 };
    // case for HTM1
    if (
      !isDesktop &&
      supportedResolutions.find(
        (sr) => sr.height === DESIRED_HMT1.height && sr.width === DESIRED_HMT1.width
      )
    ) {
      return DESIRED_HMT1;
    } else {
      // try full hd
      if (
        supportedResolutions.find(
          (sr) => sr.height === 1080 && sr.width === 1920
        )
      ) {
        return { width: 1920, height: 1080 };
      } else {
        return supportedResolutions?.[0];
      }
    }
  };

  const turnOnCamera = useCallback(
    async (flag, screenShare) => {
      flag = flag ?? !isVideoOn;
      setIsVideoOn(flag);
      const isLandscape = window.innerWidth > window.innerHeight;
      console.log("isLandscape", isLandscape);

      const supportedResolutions = await findSupportedResolutions();
      console.log(supportedResolutions);
      setAux(JSON.stringify(supportedResolutions) + "." + navigator.userAgent);
      if (screenShare) {
        videoTrack.current = await createScreenVideoTrack();
      } else {
        const calculatedRes = handleResolutions(supportedResolutions);
        console.log("calculatedRes!!!!!", supportedResolutions, calculatedRes);
        if (calculatedRes) {
          setAux((aux) => aux + "bigRes:" + JSON.stringify(calculatedRes));
          console.log('after0');
          videoTrack.current = await createCameraVideoTrack({
            encoderConfig: {
              width: { ideal: calculatedRes.width },
              height: { ideal: calculatedRes.height },
              frameRate: { min: 15, max: 30, ideal: 30 },
              bitrateMax: 5000,
              bitrateMin: 2000,
            },
            facingMode: "environment",
          });
          console.log('after1');
        } else {
          videoTrack.current = await createCameraVideoTrack({
            facingMode: "environment",
          });
          console.log('after2');
        }
      }
      videoTrack.current?.play("camera-video");
      console.log('after3');
      const videoRef = document.getElementById("camera-video");
      if (videoRef) {
        videoRef.style.transform = "none";
        videoRef.style.objectFit = "cover";
        // videoRef.style.width = "auto";
        videoRef.style.width = "100%";
        videoRef.style.height = `${
          (CONFIG.MAIN_HEIGHT / 100) * window.innerHeight
        }px`;
      }
      return videoTrack.current;
    },
    [isVideoOn, supportedResolutions]
  );

  const turnOnMicrophone = useCallback(
    async (flag) => {
      flag = flag ?? !isAudioOn;
      setIsAudioOn(flag);

      // if (audioTrack.current) {
      //   return audioTrack.current.setEnabled(flag);
      // }

      audioTrack.current = await createMicrophoneAudioTrack();
      return audioTrack.current;
      // audioTrack.play();
    },
    [isAudioOn]
  );

  const onUserPublish = useCallback(async (user, mediaType) => {
    console.log("onUserPublish", user, mediaType);
    setIsVideoMuted(user._video_muted_);
    setIsAudioMuted(user._audio_muted_);
    if (mediaType === "video") {
      const remoteTrack = await client.subscribe(user, mediaType);
      remoteTrack.play("remote-video");
      setIsRemoteVideoPubed(true);
      setIsVideoPubed(true);
    }
    if (mediaType === "audio") {
      const remoteTrack = await client.subscribe(user, mediaType);
      remoteTrack.play();
      setIsAudioPubed(true);
    }
  }, []);

  const onUserUnpublish = useCallback((e) => {
    console.log("onUserUnpublish", e);
    setIsRemoteVideoPubed(false);
    if (e._video_muted_) {
      setIsVideoMuted(true);
    }
    if (e._audio_muted_) {
      setIsAudioMuted(true);
    }
    if (!e.__audioTrack && !e._videoTrack) {
      // disconnected
      setIsVideoPubed(false);
      setIsAudioPubed(false);
    }
  }, []);

  const joinChannel = useCallback(async () => {
    console.log("joinChannel called", isJoined);
    if (!channel.current) {
      channel.current = "react-room";
    }

    console.log("isJoined", isJoined);
    if (isJoined) {
      setIsAudioPubed(false);
      setIsVideoPubed(false);
      // videoTrack.current?.close();
      // audioTrack.current?.close();
      // await client.leave();
      await client.unpublish();
    }

    client.on("user-published", onUserPublish);
    client.on("user-unpublished", onUserUnpublish);

    if (!isJoined) {
      const id = await client.join(
        appid.current,
        channel.current,
        token.current || null,
        null
      );
      console.log("joined to ", id);
    }
    setIsJoined(true);
  }, [isJoined, onUserPublish, onUserUnpublish]);

  const publish = useCallback(async () => {
    console.log("publish called", isScreenShare);
    const videoTrackRes = await turnOnCamera(true, isScreenShare);
    console.log("videoTrackRes choosen");
    await joinChannel();
    await client.publish(videoTrackRes);
    setIsVideoPubed(true);
    const microphoneRes = await turnOnMicrophone(true);
    await client.publish(microphoneRes);
    // if (!isScreenShare) {
    // } else {
    //   setIsAudioPubed(false);
    // }
  }, [isScreenShare, joinChannel, turnOnCamera, turnOnMicrophone]);

  useEffect(() => {
    const load = async () => {
      try {
        setIsLoading(true)
        const data = await startCall(channelId);
        const launchedAt = data?.launchedAt;
        const otherName = data?.attendees?.find(
          (a) => a._id !== user?._id
        )?.name;
        setOtherName(otherName || "");
        setLaunchedAt(launchedAt || new Date());
        await retryWithDelay(publish, 5, 3000);
      } catch (error) {
        console.error("error!", error);
        if (axios.isAxiosError(error)) {
          const msg = error.response?.data || error.message;
          toast({ title: msg, status: "error" });
        }
        toast({ title: "cannot launch!", status: "error" });
        window.location?.reload()
      } finally {
        setIsLoading(false)
      }
    };
    if (channelId && enterpriseId && user?._id) {
      load()
    }
  }, [isScreenShare]);

  useEffect(() => {
    socket?.on("captured", () => {
      setHideYourVideo(true);
    });
  }, [socket]);

  // this leaves the call but don't finish it on the server side
  const leaveCall = async () => {
    videoTrack.current?.close();
    audioTrack.current?.close();
    await client.leave();
  };

  // if user triggered end finish close call
  const onCloseCall = async () => {
    videoTrack.current?.close();
    audioTrack.current?.close();
    await client.leave();

    if (closeCall) await closeCall(channelId);
    setCallEnded(true);
  };

  const handleMuteUnmute = () => {
    audioTrack.current?.setMuted(!audioTrack.current?.muted);
  };

  const handleHideShowVideo = () => {
    videoTrack.current?.setMuted(!videoTrack.current?.muted);
  };

  const handleHideShowYourStream = () => {
    setHideYourVideo(!hideYourVideo);
  };

  const handleRecord = () => {
    // TODO
    console.log("TODO recording feature");
    toast({
      title: isRecording ? "Record Stopped" : "Record started",
      status: "info",
    });
    setIsRecording(!isRecording);
  };

  return (
    <VStack w="full">
      {isCallStarting && (
        <HStack pos={"absolute"} justifyContent={"center"} mt={8} w="full">
          <Spinner />
        </HStack>
      )}
      {isErrorStarting && (
        <Text fontWeight={"bold"} fontSize={"xl"} mt={8}>
          Cannot launch the call. {errorStarting.data}.
        </Text>
      )}
      {isLoading && <Box display={'flex'} zIndex={1000000} alignItems={'center'} justifyContent={'center'} w={'full'} height={`calc(var(--vh, 1vh) * ${CONFIG.MAIN_HEIGHT})`} backgroundColor={COLORS.primary} opacity={1}> <Spinner /></Box>}
      <Box
        w={"full"}
        pos={"relative"}
        style={{
          display: !isSuccessStarting || callEnded ? "none" : "block",
        }}
      >
        <Box zIndex={hideYourVideo ? 0 : 1700} pos={"absolute"} w={"100%"}>
          <YouVideo isVideoOn={isVideoOn} width={"100%"} />
        </Box>
        <HStack zIndex={1700} pos={"relative"} top={0} left={0}>
          {/* user's video */}
          <Box
            display={hideYourVideo || !isRemoteVideoPubed ? "none" : "block"}
          >
            <RemoteVideo
              callEnded={callEnded}
              isAudioMuted={isAudioMuted}
              isVideoMuted={isVideoMuted}
              largeVideoRef={largeVideoRef}
              otherName={otherName}
              width={"15%"}
              isTech={isTech}
            />
          </Box>
          <Text pos={"absolute"} top={0} right={0} width={"50%"}>
            {/* aux!! {aux} */}
          </Text>
        </HStack>
        <Box pos={"relative"}>
          <WhiteBoard
            roomId={callId}
            isSuccess={isSuccessStarting}
            isTech={true}
            callData={callData}
            height={CONFIG.MAIN_HEIGHT}
          />
        </Box>
      </Box>
      {isSuccessStarting && (
        <BottomActions
          handleMuteUnmute={handleMuteUnmute}
          audioTrack={audioTrack}
          videoTrack={videoTrack}
          handleHideShowVideo={handleHideShowVideo}
          handleHideShowYourStream={handleHideShowYourStream}
          hideYourVideo={hideYourVideo}
          setIsScreenShare={setIsScreenShare}
          isScreenShare={isScreenShare}
          meetingEllapsedTime={meetingEllapsedTime}
          isTech={isTech}
          onCloseCall={onCloseCall}
          toast={toast}
          handleRecord={handleRecord}
          isRecording={isRecording}
        />
      )}
    </VStack>
  );
};

export default VideoPlayer;
