import React, { useEffect } from "react";
import { Socket, io } from "socket.io-client";
import * as Sentry from "@sentry/browser";
import { IncomingAppointmentCall } from "../models/IncomingAppointmentCall";
import { IncomingCall } from "../models/IncomingCall";
import User from "../models/User";
import AuthService from "../services/authService";
import {
  getEnterpriseId,
  handleLoadUserSession,
  handleSaveUserSession,
  hanldeCleanSession,
} from "../utils/auth";

interface AuthContextType {
  user?: User;
  signin: (user: string) => Promise<void>;
  signInWithCredentials: (username: string, password: string) => Promise<void>;
  signout: () => void;
  isLoading?: boolean;
  isError?: boolean;
  enterpriseId?: string;
  incomingCall: IncomingCall[] | undefined;
  setIncomingCall: React.Dispatch<
    React.SetStateAction<IncomingCall[] | undefined>
  >;
  incomingAppointmentCall: IncomingAppointmentCall | undefined;
  setIncomingAppointmentCall: React.Dispatch<
    React.SetStateAction<IncomingAppointmentCall | undefined>
  >;
  socket?: Socket;
}

const handleSocket = (
  token: string,
  setSocket: React.Dispatch<React.SetStateAction<Socket | undefined>>
) => {
  const socket = io(import.meta.env.VITE_APP_WEB_SOCKET_URL, {
    transports: ["websocket"],
    auth: {
      token,
    },
    // query: {
    //   pathname,
    // },
  });
  setSocket(socket);
  return socket;
};

export const AuthContext = React.createContext<AuthContextType>(null!);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<User | undefined>(
    handleLoadUserSession()
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [incomingCall, setIncomingCall] = React.useState<IncomingCall[]>();
  const [incomingAppointmentCall, setIncomingAppointmentCall] =
    React.useState<IncomingAppointmentCall>();
  const enterpriseId = getEnterpriseId(user);
  const [socket, setSocket] = React.useState<Socket>();

  const signin = async (newUser: string) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await AuthService.qrCodeLogin(newUser);

      setUser(response.data);
      handleSaveUserSession(response.data);
      const jwt = response.data?.jwt;
      if (!jwt) return alert("opps, something went wrong!");
      localStorage.setItem("jwt", jwt);
      handleSocket(jwt, setSocket);
    } catch (error) {
      setIsError(true);
      console.error(error);
      hanldeCleanSession(); // clean session just in case
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const signInWithCredentials = async (username: string, password: string) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await AuthService.signInWithCredentials(
        username,
        password
      );

      setUser(response.data);
      handleSaveUserSession(response.data);
      const jwt = response.data?.jwt;
      if (!jwt) return alert("opps, something went wrong!");
      localStorage.setItem("jwt", jwt);
      handleSocket(jwt, setSocket);
    } catch (error) {
      setIsError(true);
      console.error(error);
      hanldeCleanSession(); // clean session just in case
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const signout = () => {
    AuthService.logout();
    setUser(undefined);
  };

  // load user
  useEffect(() => {
    const jwt = localStorage.getItem("jwt");
    const load = async () => {
      try {
        const user = await AuthService.getUser();
        setUser(user.data);
        Sentry.setUser({
          email: user.data?.email,
          id: user.data?.id,
          username: user.data?.ubimaxId,
        });
      } catch (error) {
        console.error(error);
      }
    };
    if (!user && jwt) {
      load();
    }
  }, [user]);

  // load socket instance
  useEffect(() => {
    const jwt = localStorage.getItem("jwt");
    if (!socket && jwt) {
      handleSocket(jwt, setSocket);
    }
  }, [socket, user]);

  useEffect(() => {
    const handleDirectCall = async (data: IncomingCall) => {
      // const { callId, host } = data;
      setIncomingCall([...(incomingCall || []), data]);
    };
    socket?.on("direct_call", handleDirectCall);
    return () => {
      socket?.off("direct_call", handleDirectCall);
    };
  }, [socket, incomingCall]);

  useEffect(() => {
    const handleDirectCall = async (data: IncomingCall) => {
      // const { callId, host } = data;
      setIncomingCall(incomingCall?.filter((ic) => ic.callId !== data.callId));
    };
    socket?.on("direct_call_not_aswered", handleDirectCall);
    return () => {
      socket?.off("direct_call_not_aswered", handleDirectCall);
    };
  }, [socket, incomingCall]);
  
  useEffect(() => {
    const handleDirectCall = async (data: IncomingCall) => {
      // const { callId, host } = data;
      setIncomingCall(incomingCall?.filter((ic) => ic.callId !== data.callId));
    };
    socket?.on("direct_call_ended", handleDirectCall);
    return () => {
      socket?.off("direct_call_ended", handleDirectCall);
    };
  }, [socket, incomingCall]);

  useEffect(() => {
    const handleAppointmentCall = async (data: IncomingAppointmentCall) => {
      // const { callId, host } = data;
      setIncomingAppointmentCall(data);
    };
    socket?.on("incoming_appointment_call", handleAppointmentCall);
    return () => {
      socket?.off("incoming_appointment_call", handleAppointmentCall);
    };
  }, [socket]);

  const value: AuthContextType = {
    user,
    isLoading,
    isError,
    enterpriseId,
    incomingCall,
    incomingAppointmentCall,
    socket,
    signin,
    signInWithCredentials,
    signout,
    setIncomingCall,
    setIncomingAppointmentCall,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
