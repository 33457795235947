import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../useAuth";
import { ProcessTeamUser, Process } from "../../models/ProcessTeamUser";
import ProcessService from "../../services/ProcessService";
import { GeneralTableFilters, LazyData } from "../../types/AppTypes";
import { getCacheFiles } from "../../utils/cache";

export const useProcesses = (lazy?: boolean) => {
  const { enterpriseId } = useAuth();
  const [data, setData] = useState<LazyData<ProcessTeamUser> | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);

  const load = useCallback(
    async (filters: GeneralTableFilters) => {
      console.log("filters", filters);

      if (enterpriseId) {
        try {
          setIsLoading(true);
          const res = await ProcessService.getProcesses(enterpriseId, filters);
          setData(res.data);
        } catch (error) {
          if (axios.isAxiosError(error)) {
            error.response?.data || error.message;
          }
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [enterpriseId]
  );

  const loadById = useCallback(
    async (id: string) => {
      if (enterpriseId) {
        try {
          setIsLoading(true);
          const res = await ProcessService.getProcessesById(enterpriseId, id);
          return res.data;
        } catch (error) {
          if (axios.isAxiosError(error)) {
            error.response?.data || error.message;
          }
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [enterpriseId]
  );

  const loadByIdAndExecute = useCallback(
    async (id: string) => {
      if (enterpriseId) {
        try {
          setIsLoading(true);
          const res = await ProcessService.getProcessAndExecuteById(
            enterpriseId,
            id
          );
          return res.data;
        } catch (error) {
          if (axios.isAxiosError(error)) {
            error.response?.data || error.message;
          }
          setIsError(true);
          throw error;
        } finally {
          setIsLoading(false);
        }
      }
    },
    [enterpriseId]
  );

  const processGoNext = useCallback(
    async (id: string, step: number) => {
      if (enterpriseId) {
        try {
          setIsLoading(true);
          const res = await ProcessService.processGoNext(
            enterpriseId,
            id,
            step
          );
          return res.data;
        } catch (error) {
          if (axios.isAxiosError(error)) {
            error.response?.data || error.message;
          }
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [enterpriseId]
  );

  const handleOnLocalSave = useCallback(
    async (process: Process) => {
      const cache = await getCacheFiles();
      const instructionUrl = ProcessService.getProcessesByIdUrl(
        enterpriseId!,
        process.id
      );

      const cacheUrlRequests = process.instructions.flatMap((i) =>
        i.files.map((f) => f.url || "")
      ) || [];
      console.log("invalidating...", cacheUrlRequests, instructionUrl);
      const invalidatedRes = await Promise.all([
        cache.delete(instructionUrl),
        ...cacheUrlRequests.map((url) =>
          cache.delete(url, { ignoreSearch: true })
        ),
      ]);
      console.log("invalidated", invalidatedRes);
      console.log("saving...", cacheUrlRequests);
      const savedRes = await Promise.all([
        loadById(process.id),
        ...cacheUrlRequests.map((v) => fetch(v)),
      ]);
      console.log("saved", savedRes);
    },
    [enterpriseId, loadById]
  );

  useEffect(() => {
    if (enterpriseId && !lazy) {
      load({ pageIndex: 0, pageSize: 10, sorting: [], text: "" });
    }
  }, [enterpriseId, load, lazy]);

  return {
    data,
    isLoading,
    isError,
    load,
    loadById,
    loadByIdAndExecute,
    handleOnLocalSave,
    processGoNext,
  };
};
