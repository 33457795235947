const COLORS = {
  primary: "#08588C",
  secondary: "#ffffff",
  accent: "#F5AD56",
//   success: "#28a745",
//   danger: "#dc3545",
//   warning: "#ffc107",
//   info: "#17a2b8",
//   light: "#f8f9fa",
//   dark: "#343a40",
  background: "#08588C",
  gray: '#9f9f9f'
};

export default COLORS;
