import { ProcessTeamUser, Process } from "../models/ProcessTeamUser";
import createHttpClient, { processFilters } from "../utils/httlClient";
import { GeneralTableFilters, LazyData } from "../types/AppTypes";
import _ from "lodash";

const client = createHttpClient();

const ProcessService = {
  getProcesses: async (enterpriseId: string, filters: GeneralTableFilters) => {
    const processedFilters = processFilters(filters)
    console.log('processedFilters', processedFilters);
    
    const response = await client.get<LazyData<ProcessTeamUser>>(
      `enterprise/${enterpriseId}/client/process/user-team?${processedFilters}`
    );
    return response;
  },
  getProcessesById: async (enterpriseId: string, pid: string) => {
    const response = await client.get<Process>(
      `enterprise/${enterpriseId}/process/${pid}`
    );
    return response;
  },
  getProcessAndExecuteById: async (enterpriseId: string, pid: string) => {
    const response = await client.get<ProcessTeamUser>(
      `enterprise/${enterpriseId}/client/process/user-team/${pid}/execute`
    );
    return response;
  },
  processGoNext: async (enterpriseId: string, pid: string, step: number) => {
    const response = await client.get<ProcessTeamUser>(
      `enterprise/${enterpriseId}/client/process/user-team/${pid}/next-step/${step}`
    );
    return response;
  },
  // this is for getting BASE image urls and avoid opaque response
  getProcessesByIdUrl: (enterpriseId: string, pid: string) =>
    `${client.defaults.baseURL}/enterprise/${enterpriseId}/process/${pid}`,
};

export default ProcessService;
