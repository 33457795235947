import { useCallback, useEffect } from "react";
import CallService from "../../services/callService";
import { useAuth } from "../useAuth";

export const usePingCall = (props: { callId: string | null }): void => {
  const { enterpriseId } = useAuth();

  const pingCall = useCallback(async () => {
    try {
      if (props.callId && enterpriseId) {
        await CallService.pingCall(enterpriseId!, props.callId);
      }
    } catch (error) {
      console.error("cannot ping call", error);
    }
  }, [enterpriseId, props.callId]);

  useEffect(() => {
    pingCall();
    const interval = setInterval(() => {
      pingCall();
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [enterpriseId, pingCall]);
};
