import React, { useState } from "react";
import { LaunchCallUsers } from "../../models/CallUsers";
import CallService from "../../services/callService";
import { useAuth } from "../useAuth";

export const useLazyLaunchCallQuery = () => {
  const { enterpriseId } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [data, setData] = React.useState<LaunchCallUsers>();

  const startCall = async (callId: string) => {
    try {
      setIsLoading(true);
      const res = await CallService.launchCall(enterpriseId!, callId);
      setData(res.data);
      setIsSuccess(true);
      return res.data;
    } catch (error) {
      setIsError(true);
      setError("cannot launch call");
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return [
    startCall,
    {
      isLoading,
      isError,
      error,
      isSuccess,
      data,
    },
  ] as const;
};
