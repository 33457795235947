import {
  CreateEnterpriseLibrary,
  EnterpriseLibrary,
  RecordingBody,
} from "../models/EnterprisLibrary";
import createHttpClient from "../utils/httlClient";

const client = createHttpClient();

const VideoInstructionsService = {
  startRecording: async (body: RecordingBody) => {
    const response = await client.post<void>(
      `enterprise/${body.enterpriseId}/library/${body.id}/start-recording`,
      body
    );
    return response;
  },
  stopRecording: async (body: RecordingBody) => {
    const response = await client.post<void>(
      `enterprise/${body.enterpriseId}/library/${body.id}/stop-recording`,
      body
    );
    return response;
  },
  create: async (enterpriseId: string, data: CreateEnterpriseLibrary) => {
    const response = await client.post<EnterpriseLibrary>(
      `enterprise/${enterpriseId}/library`,
      data
    );
    return response;
  },
  get: async (enterpriseId: string, id: string) => {
    const response = await client.get<EnterpriseLibrary>(
      `enterprise/${enterpriseId}/library/${id}`
    );
    return response;
  },
};

export default VideoInstructionsService;
