import { ChakraProvider } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AuthProvider from "./contexts/AuthProvider";
import { useAuth } from "./hooks/useAuth";
import Layout from "./layouts/Layout";
import AppointmentCall from "./pages/AppointmentCall";
import DirectCall from "./pages/DirectCall";
import CallableUsers from "./pages/CallableUsers";
import LoginPage from "./pages/Login";
import ProcessWorkInstructions from "./pages/ProcessWorkInstructions";
import ProcessesHome from "./pages/ProcessesHome";
import SplashDashboard from "./pages/SplashDashboard";
import theme from "./theme/theme";
import ExecuteInstruction from "./pages/ExecuteInstruction";
import AppProvider from "./contexts/AppProvider";
import GlobalNotifications from "./components/notifications/GlobalNotifications";
import VideoInstructions from "./pages/VideoInstructions";

const protectedPages: { path: string; element: React.JSX.Element }[] = [
  {
    path: "/dashboard",
    element: <SplashDashboard />,
  },
  {
    path: "/callable-users",
    element: <CallableUsers />,
  },
  {
    path: "/direct-call",
    element: <DirectCall />,
  },
  {
    path: "/processes/home",
    element: <ProcessesHome />,
  },
  {
    path: "/processes/list/:type",
    element: <ProcessWorkInstructions />,
  },
  {
    path: "/processes/execute/:id",
    element: <ExecuteInstruction />,
  },
  {
    path: "/appointment-call",
    element: <AppointmentCall />,
  },
  {
    path: "/video-instructions",
    element: <VideoInstructions />,
  },
];

export default function App() {
  
  useEffect(() => {
    const adjustHeight = () => {
      const viewportHeight = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${viewportHeight}px`);
    };

    // Adjust on resize or orientation change
    window.addEventListener("resize", adjustHeight);
    window.addEventListener("orientationchange", adjustHeight);

    // Initial adjust
    adjustHeight();

    return () => {
      window.removeEventListener("resize", adjustHeight);
      window.removeEventListener("orientationchange", adjustHeight);
    };
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <AppProvider>
        <AuthProvider>
          <Routes>
            <Route element={<Layout />}>
              {protectedPages.map((page) => (
                <Route
                  path={page.path}
                  element={<RequireAuth>{page.element}</RequireAuth>}
                />
              ))}
              <Route path="/login" element={<LoginPage />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Route>
          </Routes>
          <GlobalNotifications />
        </AuthProvider>
      </AppProvider>
    </ChakraProvider>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
