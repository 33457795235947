import {
	InstancePresenceRecordType,
	TLAnyShapeUtilConstructor,
	TLInstancePresence,
	TLRecord,
	TLStoreWithStatus,
	computed,
	createPresenceStateDerivation,
	createTLStore,
	defaultShapeUtils,
	defaultUserPreferences,
	getUserPreferences,
	setUserPreferences,
	react,
	transact,
} from '@tldraw/tldraw'
import { useEffect, useMemo, useState } from 'react'
import { YKeyValue } from 'y-utility/y-keyvalue'
import { WebsocketProvider } from 'y-websocket'
import * as Y from 'yjs'


export const DEFAULT_STORE = {
	store: {
		'document:document': {
			gridSize: 10,
			name: '',
			meta: {},
			id: 'document:document',
			typeName: 'document',
		},
		'pointer:pointer': {
			id: 'pointer:pointer',
			typeName: 'pointer',
			x: 0,
			y: 0,
			lastActivityTimestamp: 0,
			meta: {},
		},
		'page:page': {
			meta: {},
			id: 'page:page',
			name: 'Page 1',
			index: 'a1',
			typeName: 'page',
		},
		'camera:page:page': {
			x: 0,
			y: 0,
			z: 1,
			meta: {},
			id: 'camera:page:page',
			typeName: 'camera',
		},
		'instance_page_state:page:page': {
			editingShapeId: null,
			croppingShapeId: null,
			selectedShapeIds: [],
			hoveredShapeId: null,
			erasingShapeIds: [],
			hintingShapeIds: [],
			focusedGroupId: null,
			meta: {},
			id: 'instance_page_state:page:page',
			pageId: 'page:page',
			typeName: 'instance_page_state',
		},
		'instance:instance': {
			followingUserId: null,
			opacityForNextShape: 1,
			stylesForNextShape: {},
			brush: null,
			scribble: null,
			cursor: {
				type: 'default',
				rotation: 0,
			},
			isFocusMode: false,
			exportBackground: true,
			isDebugMode: false,
			isToolLocked: false,
			screenBounds: {
				x: 0,
				y: 0,
				w: 720,
				h: 400,
			},
			zoomBrush: null,
			isGridMode: false,
			isPenMode: false,
			chatMessage: '',
			isChatting: false,
			highlightedUserIds: [],
			canMoveCamera: true,
			isFocused: true,
			devicePixelRatio: 2,
			isCoarsePointer: false,
			isHoveringCanvas: false,
			openMenus: [],
			isChangingStyle: false,
			isReadonly: false,
			meta: {},
			id: 'instance:instance',
			currentPageId: 'page:page',
			typeName: 'instance',
		},
	},
	schema: {
		schemaVersion: 1,
		storeVersion: 4,
		recordVersions: {
			asset: {
				version: 1,
				subTypeKey: 'type',
				subTypeVersions: {
					image: 2,
					video: 2,
					bookmark: 0,
				},
			},
			camera: {
				version: 1,
			},
			document: {
				version: 2,
			},
			instance: {
				version: 21,
			},
			instance_page_state: {
				version: 5,
			},
			page: {
				version: 1,
			},
			shape: {
				version: 3,
				subTypeKey: 'type',
				subTypeVersions: {
					group: 0,
					text: 1,
					bookmark: 1,
					draw: 1,
					geo: 7,
					note: 4,
					line: 1,
					frame: 0,
					arrow: 1,
					highlight: 0,
					embed: 4,
					image: 2,
					video: 1,
				},
			},
			instance_presence: {
				version: 5,
			},
			pointer: {
				version: 1,
			},
		},
	},
}

export function useYjsStore({
	roomId = 'example',
	hostUrl = import.meta.env.MODE === 'development'
		? 'ws://localhost:1234'
		: 'wss://demos.yjs.dev',
	shapeUtils = [],
}) {
	const [store] = useState(() => {
		const store = createTLStore({
			shapeUtils: [...defaultShapeUtils, ...shapeUtils],
		})
		store.loadSnapshot(DEFAULT_STORE)
		return store
	})

	const [storeWithStatus, setStoreWithStatus] = useState({
		status: 'loading',
	})

	const { yDoc, yStore, room } = useMemo(() => {
		const yDoc = new Y.Doc({ gc: true })
		const yArr = yDoc.getArray(`tl_${roomId}`)
		const yStore = new YKeyValue(yArr)

		return {
			yDoc,
			yStore,
			room: new WebsocketProvider(hostUrl, roomId, yDoc, { connect: true }),
		}
	}, [hostUrl, roomId])

	useEffect(() => {
		setStoreWithStatus({ status: 'loading' })

		const unsubs = []

		function handleSync() {
			// 1.
			// Connect store to yjs store and vis versa, for both the document and awareness

			/* -------------------- Document -------------------- */

			// Sync store changes to the yjs doc
			unsubs.push(
				store.listen(
					function syncStoreChangesToYjsDoc({ changes }) {
						yDoc.transact(() => {
							Object.values(changes.added).forEach((record) => {
								yStore.set(record.id, record)
							})

							Object.values(changes.updated).forEach(([_, record]) => {
								yStore.set(record.id, record)
							})

							Object.values(changes.removed).forEach((record) => {
								yStore.delete(record.id)
							})
						})
					},
					{ source: 'user', scope: 'document' } // only sync user's document changes
				)
			)

			// Sync the yjs doc changes to the store
			const handleChange = (
				changes,
				transaction
			) => {
				if (transaction.local) return

				const toRemove = []
				const toPut = []

				changes.forEach((change, id) => {
					switch (change.action) {
						case 'add':
						case 'update': {
							const record = yStore.get(id)
							toPut.push(record)
							break
						}
						case 'delete': {
							toRemove.push(id)
							break
						}
					}
				})

				// put / remove the records in the store
				store.mergeRemoteChanges(() => {
					if (toRemove.length) store.remove(toRemove)
					if (toPut.length) store.put(toPut)
				})
			}

			yStore.on('change', handleChange)
			unsubs.push(() => yStore.off('change', handleChange))

			/* -------------------- Awareness ------------------- */

			const yClientId = room.awareness.clientID.toString()
			setUserPreferences({ id: yClientId })

			const userPreferences = computed('userPreferences', () => {
				const user = getUserPreferences()
				return {
					id: user.id,
					color: user.color ?? defaultUserPreferences.color,
					name: user.name ?? defaultUserPreferences.name,
				}
			})

			// Create the instance presence derivation
			const presenceId = InstancePresenceRecordType.createId(yClientId)
			const presenceDerivation =
				createPresenceStateDerivation(userPreferences, presenceId)(store)

			// Set our initial presence from the derivation's current value
			room.awareness.setLocalStateField('presence', presenceDerivation.value)

			// When the derivation change, sync presence to to yjs awareness
			unsubs.push(
				react('when presence changes', () => {
					const presence = presenceDerivation.value
					requestAnimationFrame(() => {
						room.awareness.setLocalStateField('presence', presence)
					})
				})
			)

			// Sync yjs awareness changes to the store
			const handleUpdate = (update) => {
				const states = room.awareness.getStates()

				const toRemove = []
				const toPut = []

				// Connect records to put / remove
				for (const clientId of update.added) {
					const state = states.get(clientId)
					if (state?.presence && state.presence.id !== presenceId) {
						toPut.push(state.presence)
					}
				}

				for (const clientId of update.updated) {
					const state = states.get(clientId)
					if (state?.presence && state.presence.id !== presenceId) {
						toPut.push(state.presence)
					}
				}

				for (const clientId of update.removed) {
					toRemove.push(
						InstancePresenceRecordType.createId(clientId.toString())
					)
				}

				// put / remove the records in the store
				store.mergeRemoteChanges(() => {
					if (toRemove.length) store.remove(toRemove)
					if (toPut.length) store.put(toPut)
				})
			}

			room.awareness.on('update', handleUpdate)
			unsubs.push(() => room.awareness.off('update', handleUpdate))

			// 2.
			// Initialize the store with the yjs doc records—or, if the yjs doc
			// is empty, initialize the yjs doc with the default store records.
			if (yStore.yarray.length) {
				// Replace the store records with the yjs doc records
				transact(() => {
					// The records here should be compatible with what's in the store
					store.clear()
					const records = yStore.yarray.toJSON().map(({ val }) => val)
					store.put(records)
				})
			} else {
				// Create the initial store records
				// Sync the store records to the yjs doc
				yDoc.transact(() => {
					for (const record of store.allRecords()) {
						yStore.set(record.id, record)
					}
				})
			}

			setStoreWithStatus({
				store,
				status: 'synced-remote',
				connectionStatus: 'online',
			})
		}

		let hasConnectedBefore = false

		function handleStatusChange({
			status,
		}) {
			// If we're disconnected, set the store status to 'synced-remote' and the connection status to 'offline'
			if (status === 'disconnected') {
				setStoreWithStatus({
					store,
					status: 'synced-remote',
					connectionStatus: 'offline',
				})
				return
			}

			room.off('synced', handleSync)

			if (status === 'connected') {
				if (hasConnectedBefore) return
				hasConnectedBefore = true
				room.on('synced', handleSync)
				unsubs.push(() => room.off('synced', handleSync))
			}
		}

		room.on('status', handleStatusChange)
		unsubs.push(() => room.off('status', handleStatusChange))

		return () => {
			unsubs.forEach((fn) => fn())
			unsubs.length = 0
		}
	}, [room, yDoc, store, yStore])

	return storeWithStatus
}