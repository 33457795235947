import React, { useState } from "react";

interface AppContextType {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
}

export const AppContext = React.createContext<AppContextType>(null!);

const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [title, setTitle] = useState("");

  const value: AppContextType = {
    title,
    setTitle,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
