import { Box, BoxProps, Center } from "@chakra-ui/react";
import { CONFIG } from "../utils/constants";
import COLORS from "../utils/colors";

const CenteredContentPage = ({
  children,
  boxProps,
  force,
}: {
  children: JSX.Element;
  boxProps?: BoxProps;
  force?: boolean;
}) => {
  return (
    <Center
      w="full"
      minH={`calc(var(--vh, 1vh) * ${
        CONFIG.MAIN_HEIGHT - CONFIG.NAVBAR_HEIGHT
      })`}
      h={
        force
          ? `calc(var(--vh, 1vh) * ${
              CONFIG.MAIN_HEIGHT - CONFIG.NAVBAR_HEIGHT
            })`
          : "auto"
      }
      overflow={force ? "hidden" : "auto"}
    >
      <Box
        mt={0}//{4}
        w="full"
        borderRadius="lg"
        // overflow="hidden"
        p={0} //{4}
        ml={0} //{4}
        mr={0} //{4}
        display={"flex"}
        justifyContent={"center"}
        // backgroundColor={COLORS.secondary}
        {...boxProps}
        // borderWidth="1px"
        // boxShadow="lg"
      >
        {children}
      </Box>
    </Center>
  );
};

export default CenteredContentPage;
