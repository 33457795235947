/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  HStack,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import {
  createCameraVideoTrack,
  createClient,
  createMicrophoneAudioTrack,
  createScreenVideoTrack,
  setLogLevel,
} from "agora-rtc-sdk-ng/esm";
import moment from "moment";
import { LegacyRef, useCallback, useEffect, useRef, useState } from "react";
import { BiMicrophone, BiMicrophoneOff } from "react-icons/bi";
import { FaStop } from "react-icons/fa";
import { FiCamera, FiCameraOff } from "react-icons/fi";
import { IoIosPeople } from "react-icons/io";
import { MdScreenShare, MdStopScreenShare } from "react-icons/md";
import { TiMediaRecord } from "react-icons/ti";
import CallIndicator from "../CallIndicator";
import { useAuth } from "../../hooks/useAuth";
import WhiteBoard from "./WhiteBoard";
import AgoraRTC, { IMicrophoneAudioTrack } from "agora-rtc-sdk-ng";
import { LaunchCallUsers } from "../../models/CallUsers";
import axios from "axios";
const APP_ID = import.meta.env.VITE_APP_AGORA_VIDEO;
import { SiSourcehut } from "react-icons/si";
import { MdHideSource } from "react-icons/md";
import { CONFIG } from "../../utils/constants";
import { isDesktop } from "../../utils/video";
import { retryWithDelay, wait } from "../../utils/helpers";
import COLORS from "../../utils/colors";
import { RecordingBody } from "../../models/EnterprisLibrary";

setLogLevel(3);

const resolutions = [
  { width: 3840, height: 2160 }, // 4K
  { width: 2560, height: 1440 }, // 2K
  { width: 1920, height: 1080 },
  { width: 1280, height: 720 },
  { width: 1024, height: 768 },
  { width: 640, height: 480 },
  // Add more resolutions as needed
];

interface IYouVideoProps {
  isVideoOn: boolean;
  width: number | string;
}
const YouVideo: React.FC<IYouVideoProps> = ({ isVideoOn, width }) => {
  return (
    <VStack
      w={width || "15%"}
      pos={"relative"}
      top={0}
      left={0}
      zIndex={1}
      width={"auto"}
      height={`calc(var(--vh, 1vh) * ${CONFIG.MAIN_HEIGHT})`}
    >
      <Text
        zIndex={1}
        position={"absolute"}
        bottom={0}
        fontSize={"sm"}
        fontWeight={"bold"}
        paddingLeft={2}
        paddingRight={2}
        backgroundColor={"rgb(255 255 255 / 50%)"}
      >
        {/* You */}
      </Text>
      <video
        style={{
          borderWidth: 2,
          borderColor: "black",
          borderStyle: "solid",
          borderRadius: 2,
          background: "black",
          // width: "192px",
          // height: "108px",
          // width: "auto",
          // height: `${(CONFIG.MAIN_HEIGHT / 100) * window.innerHeight}px`,
          transform: "none",
        }}
        id="camera-video"
        hidden={isVideoOn ? false : true}
      />
    </VStack>
  );
};

interface IBottomActionsProps {
  handleRecord: () => void;
  onCloseCall: () => void;
  isRecording: boolean;
  isStartRecordingLoading: boolean;
  isStopRecordingLoading: boolean;
}
const BottomActions: React.FC<IBottomActionsProps> = ({
  onCloseCall,
  handleRecord,
  isRecording,
  isStartRecordingLoading,
  isStopRecordingLoading,
}) => (
  <HStack
    w={"full"}
    alignSelf={"flex-end"}
    justifyContent={"space-between"}
    pos={"absolute"}
    bottom={0}
    left={0}
    right={0}
    paddingX={5}
    zIndex={1800}
  >
    <HStack gap={0} justifyContent={"center"} backgroundColor={"white"}>
      <Button
        size={"sm"}
        onClick={handleRecord}
        variant={"ghost"}
        isLoading={isStopRecordingLoading || isStartRecordingLoading}
        title={"Record"}
      >
        {isRecording ? (
          <HStack>
            <FaStop size={25} />
            <CallIndicator />
          </HStack>
        ) : (
          <TiMediaRecord size={25} />
        )}
      </Button>
    </HStack>
    <HStack>
      <Button size={"sm"} colorScheme={"red"} onClick={onCloseCall}>
        Close
      </Button>
    </HStack>
  </HStack>
);

const client = createClient({
  mode: "rtc",
  codec: "vp8",
});

interface IVideoPlayer {
  channelId: any;
  startRecording: (a: RecordingBody) => Promise<void>;
  stopRecording: (a: RecordingBody) => Promise<void>;
  onCloseCallCallback: (a?: any) => void;
  isStartRecordingLoading: boolean;
  isStopRecordingLoading: boolean;
}
const VideoRecorder: React.FC<IVideoPlayer> = ({
  channelId,
  startRecording,
  stopRecording,
  isStartRecordingLoading,
  isStopRecordingLoading,
  onCloseCallCallback,
}) => {
  const { enterpriseId, user, socket } = useAuth();
  const toast = useToast();
  const channel = useRef(channelId);
  // you can apply appid follow the guide https://www.agora.io/en/blog/how-to-get-started-with-agora/
  const appid = useRef(APP_ID);
  // you can apply token follow the guide https://www.agora.io/en/blog/how-to-get-started-with-agora/
  const token = useRef("");
  const largeVideoRef = useRef<any>();
  const [videoHeight, setVideoHeight] = useState<number | string>();
  const [aux, setAux] = useState("");
  const [supportedResolutions, setSupportedResolutions] =
    useState<{ width: number; height: number }[]>();
  const [isAudioOn, setIsAudioOn] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [agoraUserId, setAgoraUserId] = useState();
  const [isAudioPubed, setIsAudioPubed] = useState(false);
  const [isVideoPubed, setIsVideoPubed] = useState(false);
  const [isRemoteVideoPubed, setIsRemoteVideoPubed] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isScreenShare, setIsScreenShare] = useState(false);
  const [hideYourVideo, setHideYourVideo] = useState(false);
  const [meetingEllapsedTime, setMeetingEllapsedTime] = useState<string>();
  const [isJoined, setIsJoined] = useState(false);
  const [otherName, setOtherName] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [launchedAt, setLaunchedAt] = useState<Date>();
  const audioTrack = useRef<any>();
  const videoTrack = useRef<any>();

  useEffect(() => {
    window.addEventListener("beforeunload", async () => {
      await leaveCall();
    });
    return () => {
      leaveCall();
    };
  }, []);

  useEffect(() => {
    // Access the height of the video element after it has been rendered
    if (largeVideoRef.current) {
      const videoHeight = largeVideoRef.current.clientHeight;
      setVideoHeight(`${videoHeight}px`);
      console.log("Video Height:", videoHeight);
    }
  }, [largeVideoRef.current?.clientHeight]);

  useEffect(() => {
    // Attach event listener for the 'onloadeddata' event
    const handleLoadedData = () => {
      console.log("changed resolution");
      // updateVideoHeight();
      const videoHeight = largeVideoRef.current?.clientHeight;
      setVideoHeight(`${videoHeight}px`);
    };

    if (largeVideoRef.current) {
      largeVideoRef.current.addEventListener("resize", handleLoadedData);
    }
  }, []);

  // Function to get all video tracks with their resolutions
  async function findSupportedResolutions() {
    const supportedResolutionsAux = [];

    for (const resolution of resolutions) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { width: resolution.width, height: resolution.height },
        });
        // If we reach this point, the resolution is supported
        supportedResolutionsAux.push(resolution);
        // We should stop using the stream once we know it works
        stream.getTracks().forEach((track) => track.stop());
        await wait(1000);
      } catch (error) {
        console.log(
          `Resolution ${resolution.width}x${resolution.height} is not supported.`
        );
      }
    }

    console.log("Supported resolutions:", supportedResolutionsAux);
    setSupportedResolutions(supportedResolutionsAux);
    return supportedResolutionsAux;
  }

  // console.log('supportedResolutions', supportedResolutions);

  const handleResolutions = (
    supportedResolutions: { width: number; height: number }[]
  ) => {
    console.log(
      "isDesktop, supportedResolutions",
      isDesktop,
      supportedResolutions
    );
    if (!supportedResolutions) return;

    const DESIRED_HMT1 = { width: 2560, height: 1440 };
    // case for HTM1
    if (
      !isDesktop &&
      supportedResolutions.find(
        (sr) =>
          sr.height === DESIRED_HMT1.height && sr.width === DESIRED_HMT1.width
      )
    ) {
      return DESIRED_HMT1;
    } else {
      // try full hd
      if (
        supportedResolutions.find(
          (sr) => sr.height === 1080 && sr.width === 1920
        )
      ) {
        return { width: 1920, height: 1080 };
      } else {
        return supportedResolutions?.[0];
      }
    }
  };

  const onUserPublish = useCallback(async (user, mediaType) => {
    console.log("onUserPublish", user, mediaType);
    setIsVideoMuted(user._video_muted_);
    setIsAudioMuted(user._audio_muted_);
    if (mediaType === "video") {
      const remoteTrack = await client.subscribe(user, mediaType);
      remoteTrack.play("remote-video");
      setIsRemoteVideoPubed(true);
      setIsVideoPubed(true);
    }
    if (mediaType === "audio") {
      const remoteTrack = await client.subscribe(user, mediaType);
      remoteTrack.play();
      setIsAudioPubed(true);
    }
  }, []);

  const onUserUnpublish = useCallback((e) => {
    console.log("onUserUnpublish", e);
    setIsRemoteVideoPubed(false);
    if (e._video_muted_) {
      setIsVideoMuted(true);
    }
    if (e._audio_muted_) {
      setIsAudioMuted(true);
    }
    if (!e.__audioTrack && !e._videoTrack) {
      // disconnected
      setIsVideoPubed(false);
      setIsAudioPubed(false);
    }
  }, []);

  const publish = useCallback(async () => {
    const joinChannel = async () => {
      console.log("joinChannel called", isJoined);
      if (!channel.current) {
        channel.current = "react-room";
      }

      console.log("isJoined", isJoined);
      if (isJoined) {
        setIsAudioPubed(false);
        setIsVideoPubed(false);
        // videoTrack.current?.close();
        // audioTrack.current?.close();
        // await client.leave();
        await client.unpublish();
      }

      client.on("user-published", onUserPublish);
      client.on("user-unpublished", onUserUnpublish);

      if (!isJoined) {
        const id = await client.join(
          appid.current,
          channel.current,
          token.current || null,
          null
        );
        console.log("joined to ", id);
      }
      setIsJoined(true);
    };
    // , [isJoined, onUserPublish, onUserUnpublish]);

    const turnOnCamera = async (flag, screenShare) => {
      flag = flag ?? !isVideoOn;
      setIsVideoOn(flag);
      const isLandscape = window.innerWidth > window.innerHeight;
      console.log("isLandscape", isLandscape);

      const supportedResolutions = await findSupportedResolutions();
      console.log(supportedResolutions);
      setAux(JSON.stringify(supportedResolutions) + "." + navigator.userAgent);
      if (screenShare) {
        videoTrack.current = await createScreenVideoTrack();
      } else {
        const calculatedRes = handleResolutions(supportedResolutions);
        console.log("calculatedRes!!!!!", supportedResolutions, calculatedRes);
        if (calculatedRes) {
          setAux((aux) => aux + "bigRes:" + JSON.stringify(calculatedRes));
          console.log("after0");
          videoTrack.current = await createCameraVideoTrack({
            encoderConfig: {
              width: { ideal: calculatedRes.width },
              height: { ideal: calculatedRes.height },
              frameRate: { min: 15, max: 30, ideal: 30 },
              bitrateMax: 5000,
              bitrateMin: 2000,
            },
            facingMode: "environment",
          });
          console.log("after1");
        } else {
          videoTrack.current = await createCameraVideoTrack({
            facingMode: "environment",
          });
          console.log("after2");
        }
      }
      videoTrack.current?.play("camera-video");
      console.log("after3");
      const videoRef = document.getElementById("camera-video");
      if (videoRef) {
        videoRef.style.transform = "none";
        videoRef.style.objectFit = "cover";
        // videoRef.style.width = "auto";
        videoRef.style.width = "100%";
        videoRef.style.height = `${
          (CONFIG.MAIN_HEIGHT / 100) * window.innerHeight
        }px`;
      }
      return videoTrack.current;
    };
    // [isVideoOn]

    const turnOnMicrophone = async (flag) => {
      flag = flag ?? !isAudioOn;
      setIsAudioOn(flag);

      // if (audioTrack.current) {
      //   return audioTrack.current.setEnabled(flag);
      // }

      audioTrack.current = await createMicrophoneAudioTrack();
      return audioTrack.current;
      // audioTrack.play();
    };
    // [isAudioOn]
    if (!isVideoOn) {
      // console.log("publish called", isScreenShare);
      const videoTrackRes = await turnOnCamera(true, false);
      console.log("videoTrackRes choosen");
      await joinChannel();
      await client.publish(videoTrackRes);
      setIsVideoPubed(true);
      const microphoneRes = await turnOnMicrophone(true);
      await client.publish(microphoneRes);
      setIsLoading(false);
    }
    // if (!isScreenShare) {
    // } else {
    //   setIsAudioPubed(false);
    // }
  }, [isAudioOn, isJoined, isVideoOn, onUserPublish, onUserUnpublish]);

  // this leaves the call but don't finish it on the server side
  const leaveCall = async () => {
    videoTrack.current?.close();
    audioTrack.current?.close();
    setIsJoined(false);
    await client.leave();
  };

  // if user triggered end finish close call
  const onCloseCall = async () => {
    videoTrack.current?.close();
    audioTrack.current?.close();
    await client.leave();
    onCloseCallCallback && onCloseCallCallback();
  };

  const handleRecord = async () => {
    await (isRecording ? stopRecording : startRecording)({
      enterpriseId: enterpriseId!,
      id: channelId,
      agoraUserId: agoraUserId!,
    });
    toast({
      title: isRecording ? "Record Stopped" : "Record started",
      status: "info",
    });
    setIsRecording(!isRecording);
  };

  useEffect(() => {
    publish();
    console.log("publish!!");
  }, [publish]);

  return (
    <VStack
      pos={"absolute"}
      top={0}
      w="full"
      height={`calc(var(--vh, 1vh) * ${CONFIG.MAIN_HEIGHT})`}
    >
      {isLoading && (
        <Box
          display={"flex"}
          zIndex={1000000}
          alignItems={"center"}
          justifyContent={"center"}
          w={"full"}
          height={`calc(var(--vh, 1vh) * ${CONFIG.MAIN_HEIGHT})`}
          backgroundColor={COLORS.primary}
          opacity={1}
        >
          {" "}
          <Spinner />
        </Box>
      )}
      <Box zIndex={1700} pos={"absolute"} w={"100%"}>
        <YouVideo isVideoOn={isVideoOn} width={"100%"} />
      </Box>
      <BottomActions
        handleRecord={handleRecord}
        isRecording={isRecording}
        isStartRecordingLoading={isStartRecordingLoading}
        isStopRecordingLoading={isStopRecordingLoading}
        onCloseCall={onCloseCall}
      />
    </VStack>
  );
};

export default VideoRecorder;
