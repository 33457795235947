import { HStack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router";
import completeIcon from "../assets/completeIcon.png";
import jobsIcon from "../assets/jobsIcon.png";
import pendingIcon from "../assets/pendingIcon.png";
import HomeOptionItem from "../components/processesHome/HomeOptionItem";
import CenteredContentPage from "../layouts/CenteredContentPage";

type HomeOptions = "jobs" | "pending" | "complete";

const ProcessesHome: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = (type: HomeOptions) =>
    navigate(`/processes/list/${type}`);
  return (
    <CenteredContentPage>
      <HStack gap={8} justifyContent={"center"} alignItems={'stretch'}>
        <HomeOptionItem
          label="Work Instructions"
          onClick={() => handleClick("jobs")}
          icon={jobsIcon}
        />
        <HomeOptionItem
          label="Pending"
          onClick={() => handleClick("pending")}
          icon={pendingIcon}
        />
        <HomeOptionItem
          label="Complete"
          onClick={() => handleClick("complete")}
          icon={completeIcon}
        />
      </HStack>
    </CenteredContentPage>
  );
};

export default ProcessesHome;
