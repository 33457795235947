import React, { useCallback, useState } from "react";
import CallService from "../../services/callService";
import { useAuth } from "../useAuth";

export const useLazyCloseCallQuery = (): [
  (callId: string) => Promise<void>,
  {
    isLoading: boolean;
    isError: boolean;
    error: string;
    isSuccess: boolean;
  }
] => {
  const { enterpriseId } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);

  const closeCall = useCallback(async (callId: string) => {
    try {
      setIsLoading(true);
      await CallService.closeCall(enterpriseId!, callId);
      setIsSuccess(true);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      setError("cannot launch call");
    }
  }, [enterpriseId])

  return [
    closeCall,
    {
      isLoading,
      isError,
      error,
      isSuccess,
    },
  ];
};
