/**
 * This represents some generic auth provider API, like Firebase.
 */

import User from "../models/User";
import { PRODUCT_SUBSCRIPTION_VALUES } from "./constants";

const simulateTimeout = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("Timeout completed");
    }, 300);
  });
};

const fakeAuthProvider = {
  isAuthenticated: false,
  async signin() {
    fakeAuthProvider.isAuthenticated = true;
    await simulateTimeout(); // fake async
  },
  async signInWithCredentials(username, password) {
    console.log(`Signing in with credentials: ${username}, ${password}`);
    await simulateTimeout(); // Simulating async operation like a network request
    this.isAuthenticated = true; // Ideally, you'd set this based on actual authentication logic
    // In a real-world scenario, you would also handle the resolution of actual credentials check here
  },
  signout(callback: VoidFunction) {
    fakeAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};



export const handleLoadUserSession: () => User | undefined = () => {
  const userStr = localStorage.getItem("user");
  let user = undefined;
  if (userStr) {
    user = JSON.parse(userStr) as User;
  }
  return user;
};

export const handleSaveUserSession = (user: User) => {
  const data = {
    country: user?.country,
    created: user?.created,
    type: user?.type,
    email: user?.email,
    emailValidation: user?.emailValidation,
    first_name: user?.first_name,
    hourlyRate: user?.hourlyRate,
    city: user?.city,
    company_name: user?.company_name,
    approvedExpert: user?.approvedExpert,
    name: user?.name,
    last_name: user?.last_name,
    onboarded: user?.onboarded,
    ubimaxId: user?.ubimaxId,
    validated: user?.validated,
    parent: {
      name: user?.parent?.name || "",
      type: user?.parent?.type,
      _id: user?.parent?._id,
    },
    subscription: {
      product: { name: user?.subscription?.product?.name },
    },
    _id: user?._id,
  };
  localStorage.setItem("user", JSON.stringify(data));
};

export const hanldeCleanSession = () => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("user");
};

export const getEnterpriseId = (user: User | undefined) => {
  if (!user) return undefined;
  if (
    [
      PRODUCT_SUBSCRIPTION_VALUES.ENTERPRISE,
      PRODUCT_SUBSCRIPTION_VALUES.UNLIMITED_ENTERPRISE,
    ].includes(user?.subscription?.product?.name)
  ) {
    // the user is an enterprise, return user id
    return user?._id;
  }
  // child user just return parent enterprise id. Child user could be an admin too.
  return user?.parent?._id || user?._id;
};

export { fakeAuthProvider };
