import { CallUsers, LaunchCallUsers } from "../models/CallUsers";
import User from "../models/User";
import createHttpClient from "../utils/httlClient";

const client = createHttpClient();

const CallService = {
  getCallableUsers: async (enterpriseId: string) => {
    const response = await client.get<User[]>(
      `enterprise/${enterpriseId}/callable-users`
    );
    return response;
  },
  launchCall: async (enterpriseId: string, callId: string) => {
    const response = await client.get<LaunchCallUsers>(
      `enterprise/${enterpriseId}/call/${callId}/launch`
    );
    return response;
  },
  callUser: async (enterpriseId: string, data: { toUser: string }) => {
    const response = await client.post<CallUsers>(
      `enterprise/${enterpriseId}/createDirectCall`,
      data
    );
    return response;
  },
  closeCall: async (enterpriseId: string, callId: string) => {
    const response = await client.get<undefined>(
      `enterprise/${enterpriseId}/call/${callId}/set-closed`
    );
    return response;
  },
  pingCall: async (enterpriseId: string, callId: string) => {
    const response = await client.get<undefined>(
      `enterprise/${enterpriseId}/call/${callId}/ping`
    );
    return response;
  },
};

export default CallService;
