import { chakra, shouldForwardProp } from "@chakra-ui/react";
import { motion, isValidMotionProp } from "framer-motion";

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

interface CallIndicatorProps {
  colorFrom?: string;
  colorTo?: string;
}
const CallIndicator = ({ colorFrom, colorTo }: CallIndicatorProps) => (
  <ChakraBox
    animate={{
      backgroundColor: [
        colorFrom || "#b50015",
        colorTo || "#fff",
        colorFrom || "#b50015",
      ],
      scale: [2, 1.5, 2],
    }}
    transition={{
      duration: 1.5,
      repeat: Infinity,
      repeatType: "loop",
    }}
    width="5px"
    height="5px"
    borderRadius={"100px"}
  />
);

export default CallIndicator;
