import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { clearAppCache, syncFailedHttpRequests } from "../utils/helpers";
import { useNavigate } from "react-router";
import { useAuth } from "../hooks/useAuth";

const ConfigOptions: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { signout, user } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [isSyncLoading, setIsSyncLoading] = useState(false);
  const [isClearLoading, setIsClearLoading] = useState(false);

  const handleOnSync = async () => {
    try {
      setIsSyncLoading(true);
      await syncFailedHttpRequests();
      toast({
        title: "All synced!",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Cannot Sync, ensure you have internet connection",
        status: "error",
      });
    } finally {
      setIsSyncLoading(false);
    }
  };

  const handleOnClearCache = async () => {
    try {
      setIsClearLoading(true);
      await clearAppCache();
      toast({
        title: "Cache cleared!",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Cannot clear cache, contact support",
        status: "error",
      });
    } finally {
      setIsClearLoading(false);
    }
  };

  const handleOnLogout = async () => {
    signout();
    navigate("/login", { replace: true });
  };

  const handleExit = () => {
    navigate("/processes/list/jobs", { replace: true });
    onClose();
  };
  
  const handleExitApp = () => {
    window.close()
  };

  return (
    <Modal
      isOpen={true}
      isCentered
      motionPreset="slideInBottom"
      size="md"
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={"center"}>
          <Text>Options</Text>
        </ModalHeader>
        <ModalCloseButton title="Close options" />
        <VStack gap={2} p={2} w="full">
          <Button w={"full"} isLoading={isSyncLoading} onClick={handleOnSync}>
            <Text>Sync with server</Text>
          </Button>
          <Button
            w={"full"}
            isLoading={isClearLoading}
            onClick={handleOnClearCache}
          >
            <Text>Clear app cache</Text>
          </Button>
          <Button
            w={"full"}
            isLoading={isClearLoading}
            onClick={handleOnLogout}
            colorScheme={"red"}
          >
            <Text>Logout</Text>
          </Button>
          <Button
            w={"full"}
            isLoading={isClearLoading}
            onClick={handleExit}
            colorScheme={"red"}
          >
            <Text>Exit Work Instruction</Text>
          </Button>
          <Button
            w={"full"}
            isLoading={isClearLoading}
            onClick={handleExitApp}
            colorScheme={"red"}
          >
            <Text>Exit App</Text>
          </Button>
        </VStack>
        <Text textAlign={"center"} fontWeight={"bold"} fontSize={"sm"}>
          v{import.meta.env.APP_VERSION} ({user?.ubimaxId})
        </Text>
        <Text textAlign={"center"} fontWeight={"bold"} fontSize={"sm"}>
          ENV: {import.meta.env.MODE}
        </Text>
      </ModalContent>
    </Modal>
  );
};

export default ConfigOptions;
