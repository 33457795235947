import createHttpClient from "./httlClient";

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const retryWithDelay = async <T>(
  fn: () => Promise<T>,
  retries = 5,
  interval = 1000,
  finalErr = "Retry failed"
): Promise<void> => {
  try {
    // try
    await fn();
  } catch (err) {
    // if no retries left
    // throw error
    if (retries <= 0) {
      return Promise.reject(finalErr);
    }

    //delay the next call
    await wait(interval);

    //recursively call the same func
    return retryWithDelay(fn, retries - 1, interval, finalErr);
  }
};

export const syncFailedHttpRequests = async () => {
  const client = createHttpClient();
  const successfulRequests: number[] = [];
  const failedRequests: [{ method: string; url: string }] =
    JSON.parse(localStorage.getItem("failedRequests") || "[]") || [];
  for (let i = 0; i < failedRequests.length; i++) {
    try {
      const failedRequest = failedRequests[i];
      const { method, url } = failedRequest;
      switch (method) {
        case "get":
          await client.get(url);
          break;
        default:
          throw Error("not implemented yet...");
      }
      successfulRequests.push(i);
    } catch (error) {
      console.error(error);
    }
  }
  const pending = failedRequests.filter(
    (_d, i) => !successfulRequests.includes(i)
  );
  localStorage.setItem("failedRequests", JSON.stringify(pending));
  successfulRequests;
};

export const clearAppCache = async () => {
  const cacheKeysToDelete = [
    "instructions-videos",
    "instructions-images",
    "instructions-data",
    "instructions-cache",
  ];
  const keys = await caches.keys();
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (cacheKeysToDelete.includes(key)) {
      console.log(`cleaning key ${key}`);
      caches.delete(key);
    }
  }
};
