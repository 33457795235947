import {
  Button,
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { IncomingCall } from "../../models/IncomingCall";
import { useNavigate } from "react-router";

interface MessageIncomingCallProps {
  call: IncomingCall;
  onClose: () => void;
  onCancelled: () => void;
  onAccept: () => void;
}
const MessageIncomingCall: React.FC<MessageIncomingCallProps> = ({
  onClose,
  onCancelled,
  onAccept,
  call,
}) => {
  const navigate = useNavigate();
  return (
    <Modal
      isOpen={!!call}
      // isCentered
      motionPreset="slideInBottom"
      size="md"
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={"center"}>
          <Text>Incoming Call from</Text>
          <Text>{call?.host}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <HStack w={"full"} justifyContent={"space-between"}>
            <Button
              variant={"accept"}
              onClick={() => {
                onClose();
                navigate(`/direct-call?callId=${call?.callId}`);
                onAccept();
              }}
            >
              Accept
            </Button>
            <Button
              variant={"cancel"}
              onClick={() => {
                onCancelled();
              }}
            >
              Decline
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MessageIncomingCall;
