import React, { useEffect } from 'react';
import { Button, Image, VStack, useToast, Box, Heading, useBreakpointValue, Flex } from "@chakra-ui/react";
import { BiPhoneCall } from "react-icons/bi";
import { FaVideo, FaClipboardList } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import CenteredContentPage from "../layouts/CenteredContentPage";
import logo from "/logo192.png";
import { useApp } from "../hooks/useApp";
import COLORS from "../utils/colors";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { setTitle } = useApp();
  const toast = useToast();
  const auth = useAuth();

  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });
  const fontSize = useBreakpointValue({ base: "lg", md: "xl", lg: "2xl" });
  const spacing = useBreakpointValue({ base: 4, md: 6 });
  const containerWidth = useBreakpointValue({ base: "95%", md: "90%", lg: "600px" });
  const containerPadding = useBreakpointValue({ base: 3, md: 6 });

  useEffect(() => {
    setTitle("Home");
  }, [setTitle]);

  const handleNavigation = (path: string, errorMessage: string) => {
    try {
      navigate(path);
    } catch (error) {
      console.error(`Failed to navigate to ${path}:`, error);
      toast({
        title: "Navigation Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const buttonStyle = {
    size: buttonSize,
    width: "100%",
    justifyContent: "flex-start",
    px: 4,
    py: { base: 3, md: 4 },
    fontSize: { base: "md", md: "lg" },
    fontWeight: "bold",
  };

  const iconSize = useBreakpointValue({ base: 18, md: 22 });

  return (
    <CenteredContentPage>
      <Box 
        bg="white" 
        borderRadius="lg" 
        p={containerPadding} 
        shadow="md" 
        w="full" 
        maxW={containerWidth}
      >
        <VStack spacing={spacing} align="stretch">
          <Flex justifyContent="center" alignItems="center" mb={2}>
            <Image src={logo} alt="Logo" h={{ base: "50px", md: "70px" }} objectFit="contain" />
          </Flex>
          <Heading size={fontSize} textAlign="center" color={COLORS.primary} mb={4}>
            Welcome, {auth.user?.name}
          </Heading>
          <Button
            leftIcon={<FaClipboardList size={iconSize} />}
            variant="outline"
            onClick={() => handleNavigation("/processes/list/jobs", "Failed to navigate to Work Instructions.")}
            {...buttonStyle}
          >
            WORK INSTRUCTIONS
          </Button>
          <Button
            leftIcon={<BiPhoneCall size={iconSize} />}
            variant="solid"
            onClick={() => handleNavigation("/callable-users", "Failed to navigate to Call option.")}
            {...buttonStyle}
            colorScheme="blue"
          >
            CALL
          </Button>
          <Button
            leftIcon={<FaVideo size={iconSize} />}
            variant="solid"
            onClick={() => handleNavigation("/video-instructions", "Failed to navigate to Video Instructions.")}
            {...buttonStyle}
            colorScheme="blue"
          >
            VIDEO INSTRUCTIONS
          </Button>
        </VStack>
      </Box>
    </CenteredContentPage>
  );
};

export default Home;