import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { BrowserRouter } from "react-router-dom";
import { registerSW } from "virtual:pwa-register";
import * as Sentry from "@sentry/react";

// add this to prompt for a refresh
const updateSW = registerSW({
  onNeedRefresh() {
    if (confirm("New content available. Reload?")) {
      updateSW(true);
    }
  },
});

Sentry.init({
  dsn: "https://0bd645e9fc0f2615e08a4c6500aad01c@o4505274580729856.ingest.us.sentry.io/4507307503714304",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({ levels: ["warning", "error"] }),
  ],
  // Performance Monitoring
  tracesSampleRate: import.meta.env.MODE === "development" ? 0 : 0.1, //  Capture 100% of the transactions
  environment: import.meta.env.MODE,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/client.staging.newforge\.pro\/api/],
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // <React.StrictMode>
  // </React.StrictMode>
);
